/* eslint-disable @typescript-eslint/no-explicit-any */
/* See: https://utilifeed.atlassian.net/l/cp/7xp11Zd1 for further details on meta data names. */

import { DateTime } from "luxon";

export const UTF_ADMIN_FLAG = "utf-admin";
export const DATE_FORMAT = "yyyy-MM-dd";
export const TIME_FORMAT = "HH:mm";
export const DATETIME_FORMAT_DEFAULT = `${DATE_FORMAT}, ${TIME_FORMAT}`; //   "2023-11-22, 13:05"
export const DATETIME_FORMAT_DISPLAY = `${DATE_FORMAT} ${TIME_FORMAT}:ss`; // "2023-11-22 13:05:50"
export const DATETIME_FORMAT_ISO_SHORT = "yyyy-MM-dd'T'HH:mm"; //             "2023-11-22T13:05"
export const DATETIME_FORMAT_ISO_SHORT_FIXED_SECONDS = "yyyy-MM-dd'T'HH:mm:'00'"; // "...2T13:05:00"

export const COLTYPE = {
  number: "num",
  str: "str",
  coord: "coord",
  datetime_s: "datetime",
  ts_s: "timestamp",
  date: "date",
  datestr: "datestr",
  datestrn: "datestrn",
  timezone: "timezone",
  yearmonth: "yearmonth",
  yearweek: "yearweek",
  json: "json",
  virtual: "virtual",
};
export const COL_PARSERS = {
  [COLTYPE.str](k: any) {
    return k;
  },
  [COLTYPE.number](k: string) {
    const v = parseFloat(k);
    if (!Number.isNaN(v) && Number.isFinite(v)) {
      return v;
    }
    return undefined;
  },
  [COLTYPE.coord](d: string) {
    if (d) {
      const coords = d.split(",").map((k: string) => parseFloat(k));
      // This is to make sure that the coordinates are valid
      // since there are some discrepancies in the data
      if (
        coords.length === 2 &&
        coords.every((k: unknown) => !Number.isNaN(k) && Number.isFinite(k))
      ) {
        return coords;
      }
    }
    return null;
  },
  [COLTYPE.datetime_s](d: string) {
    if (d) {
      const v = DateTime.fromISO(d, { zone: "UTC+00:00" });
      return v.isValid ? v.toMillis() : null;
    }
    return null;
  },
  [COLTYPE.ts_s](d: number) {
    return d ? d * 1000 : null;
  },
  [COLTYPE.date](d: string) {
    if (d) {
      const v = DateTime.fromFormat(d, "yyyy-MM-dd HH:mm:ss", { zone: "UTC+00:00" });
      return v.isValid ? v : null;
    }
    return null;
  },
  [COLTYPE.datestr](d: string) {
    if (d) {
      const v = DateTime.fromFormat(d, "yyyy-MM-dd HH:mm:ss", { zone: "UTC+00:00" });
      return v.isValid ? v : null;
    }
    return null;
  },
  [COLTYPE.datestrn](d: string) {
    if (d) {
      const v = DateTime.fromISO(d, { zone: "UTC+00:00" });
      return v.isValid ? v : null;
    }
    return null;
  },
  [COLTYPE.timezone](d: any) {
    if (d) {
      return `UTC${d}`;
    }
    return null;
  },
  [COLTYPE.yearmonth](d: string) {
    if (d) {
      return d.split("-").map((k: string) => parseInt(k));
    }
    return null;
  },
  [COLTYPE.json](d: string) {
    if (d) {
      // Check if it is a object, otherwise parse
      return typeof d === "object" ? d : JSON.parse(d);
    }
    return null;
  },
  [COLTYPE.yearweek](d: {
    split: (arg0: string) => {
      (): any;
      new (): any;
      map: { (arg0: (k: any) => number): [any, any]; new (): any };
    };
  }) {
    if (d) {
      const [y, w] = d.split("-").map((k: string) => parseInt(k));
      return DateTime.fromObject({ weekYear: y, weekNumber: w });
    }
    return null;
  },
};

export const R12 = "rolling 12 months";
export const METER_TYPE = "meter_type";

export const FLOW_REDUCTION_CONSTANT = 1.16;

export const T = "t";
export const TIME_WEATHER = "datetime";
export const CHART_MAPPER = {
  histogram: "bar",
  categorical: "scatter",
};

export const IDX_COL = "resource_name";
export const VALUETYPES = {
  normal: "epc_",
  measured: "",
};

export const VALUETYPES_OPTIONS = new Map([
  [VALUETYPES.normal, "Normal"],
  [VALUETYPES.measured, "Measured"],
]);

export const AVERAGETYPES = {
  flow_weighted: "flowweighted_avg",
  non_weighted: "unweighted_avg",
  power_weighted: "powerweighted_avg",
};

export const AVERAGETYPES_OPTIONS = new Map([
  [AVERAGETYPES.flow_weighted, "Flow Weighted"],
  [AVERAGETYPES.non_weighted, "Non Weighted"],
  [AVERAGETYPES.power_weighted, "Power Weighted"],
]);

export const WEEKDAY_NAME = {
  1: "text_monday",
  2: "text_tuesday",
  3: "text_wednesday",
  4: "text_thursday",
  5: "text_friday",
  6: "text_Saturday",
  7: "text_Sunday",
};

export const MONTH_INT = {
  all: null,
  jan: "01",
  feb: "02",
  mar: "03",
  apr: "04",
  may: "05",
  jun: "06",
  jul: "07",
  aug: "08",
  sep: "09",
  oct: "10",
  nov: "11",
  dec: "12",
};

type MonthKey = keyof typeof MONTH_INT; // 'jan' | 'feb' | ... | 'dec'

export const MONTHS_NAME = {
  null: "text_all_year",
  "01": "text_month_1",
  "02": "text_month_2",
  "03": "text_month_3",
  "04": "text_month_4",
  "05": "text_month_5",
  "06": "text_month_6",
  "07": "text_month_7",
  "08": "text_month_8",
  "09": "text_month_9",
  10: "text_month_10",
  11: "text_month_11",
  12: "text_month_12",
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const MONTH_LBL = {
  all: "text_all_year",
  jan: "Jan",
  feb: "Feb",
  mar: "Mar",
  apr: "Apr",
  may: "May",
  jun: "Jun",
  jul: "Jul",
  aug: "Aug",
  sep: "Sep",
  oct: "Oct",
  nov: "Nov",
  dec: "Dec",
};

export const MONTH_CHOICES: MonthKey[] = [
  "all",
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

export const MONTH_OPTIONS = new Map(
  MONTH_CHOICES.map((month: MonthKey) => [MONTH_INT[month], MONTH_LBL[month]])
);

export const INTERVAL_HOURLY = "1h";
export const INTERVAL_DAILY = "24h";

export const INTERVAL_OPTIONS = new Map([
  [INTERVAL_HOURLY, "text_hourly"],
  [INTERVAL_DAILY, "text_daily"],
]);

export const SPEED_OPTIONS = [
  { label: "text_last_week", type: "week", value: 1, disableMonth: 0.25 }, // 1 week
  { label: "text_last_month", type: "month", value: 1, disableMonth: 1 }, // 1 month
  { label: "text_last_3_months", type: "months", value: 3, disableMonth: 3 }, // 3 months
  { label: "text_last_year", type: "year", value: 1, disableMonth: 12 }, // 1 year
];

export const SUBSTATION = "substation";
export const CLUSTER = "cluster";

export const RESOURCE_OPTIONS = new Map([
  [SUBSTATION, "Substation"],
  [CLUSTER, "Cluster"],
]);

export const BLOCKS = [
  "core_monthly",
  "core_yearly",
  "install_address",
  "epcore",
  "ep_peak_power",
  "measured_peak_power",
  "location",
  "customer",
  "location",
  "pricing",
  "distribution",
];

export const SPLIT_TYPE = {
  categorical: "categorical",
  measurement: "measurement",
};

export const SPLIT_CATEGORY = {
  grid_area: {
    block: "distribution",
    column: "grid_area",
    label: "Grid Area",
  },
  segment: {
    block: "pricing",
    column: "segment",
    label: "Segment",
  },
  customer_name: {
    block: "customer",
    column: "name",
    label: "Customer Name",
  },
  tariff: {
    block: "pricing",
    column: "tariff_id",
    label: "Tariff Id",
  },
  price_area: {
    block: "pricing",
    column: "price_area",
    label: "PricingArea",
  },
  power_calc_method: {
    block: "pricing",
    column: "power_calc_method",
    label: "Power Calculation Method",
  },
};

export const PRICING_POWER_COSTUNIT = {
  subscribed_power: "subscribed_power",
  flow_limit: "flow_limit",
  wn_value: "wn_value",
};

export const PRICING_POWER_COSTUNIT_LABELS = {
  [PRICING_POWER_COSTUNIT.subscribed_power]: "kW",
  [PRICING_POWER_COSTUNIT.flow_limit]: "m³",
  [PRICING_POWER_COSTUNIT.wn_value]: "Wn",
};

export const PRICING_POWER_COSTUNITS_OPTIONS = Object.keys(PRICING_POWER_COSTUNIT).map((k) => ({
  value: k,
  label: PRICING_POWER_COSTUNIT_LABELS[k],
}));

export const PRICING_POWER_COMPONENT_TYPE = {
  measured: "measured",
  power_signature: "power",
  subscription: "subscription",
  volume: "volume",
};

export const PRICING_POWER_COMPONENT_TYPE_LABELS = {
  [PRICING_POWER_COMPONENT_TYPE.measured]: "text_measured_power",
  [PRICING_POWER_COMPONENT_TYPE.power_signature]: "text_power_signature",
  [PRICING_POWER_COMPONENT_TYPE.subscription]: "text_subscribed_power",
  [PRICING_POWER_COMPONENT_TYPE.volume]: "text_measured_volume",
};

export const PRICING_POWER_PERIOD = {
  calendar_year: "calendar_year",
  twelve_month: "twelve_month",
  one_month: "one_month",
};

export const PRICING_POWER_PERIOD_LABELS = {
  [PRICING_POWER_PERIOD.calendar_year]: "text_calendar_year",
  [PRICING_POWER_PERIOD.twelve_month]: "text_last_12_months",
  [PRICING_POWER_PERIOD.one_month]: "text_present_month",
};

export const PRICING_TEMP_AVG_OPTIONS = {
  DAILY: "daily",
  HOURLY: "hourly",
};

export const PRICING_NPEAKS_OPTIONS = {
  TOP_ONE: "top1",
  TOP_THREE: "top3",
  TOP_FIVE: "top5",
};

export const HOUR_MILIS = 3600000;
export const DAY_MILIS = 86400000;

export const PAGE_PORTAL_EL_ID = "utf-page-portal";

export const UNITS = {
  heat: "kW",
  energy: "kWh",
  temp: "°C",
  flow: "m³/h",
  vol: "m3",
  string: "s",
};
