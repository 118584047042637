import FlagIcon from "@mui/icons-material/Flag";

export function getFaultLevel(type: string, faults: string[] = []): JSX.Element | null {
  // faults is an array eg ["fv", "ft", "fe"]
  // fv = fault_flow
  // ft = fault_supplytemp_dipp
  // fe = fault_return_temperature
  // Return the highest priority fault
  if (faults.includes(type)) {
    return <FlagIcon color="error" />;
  }
  return null;
}
