import { observer } from "mobx-react";
import { Box, hexToRgb, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/highstock";
import hcBoost from "highcharts/modules/boost";
import { DateTime } from "luxon";

import { useConsumption } from "./Consumption.store";
import { consumptionLogger } from "./utils";

hcBoost(Highcharts);

const logger = consumptionLogger.getSubLogger({ name: "<ChartNavigator/>" });

type ChartNavigatorProps = {
  chartRef?: React.RefObject<HighchartsReact.RefObject> | null;
  width?: number;
};

export const ChartNavigator = observer(
  ({ chartRef: navigatorChartRef = null, width = 0 }: ChartNavigatorProps) => {
    const theme = useTheme();
    const consumption = useConsumption();
    const {
      fetchedRange,
      hasPartialData,
      hasCompleteData,
      seriesData,
      onNavigatorExtremesUpdated,
    } = consumption;
    const startDateTs = fetchedRange.start?.ts;

    if (!hasPartialData) return <Skeleton variant="rectangular" height={30} />;

    const series = [
      {
        type: "spline",
        name: "",
        pointStart: startDateTs,
        pointInterval: 3600 * 1000, // We just show hourly data
        data: seriesData.map((row) => {
          if (hasCompleteData === null) return row.heat;

          return row.outdoor;
        }),
        lineWidth: 0,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
        yAxis: 0,
      },
    ];

    logger.trace("RENDER");

    return (
      <Box
        sx={{
          borderTop: `${theme.palette.grey["50"]} 1px solid`,
          borderBottom: `${theme.palette.grey["50"]} 1px solid`,
        }}
      >
        <HighchartsReact
          ref={navigatorChartRef}
          highcharts={Highcharts}
          constructorType="stockChart"
          options={{
            boost: {
              enabled: true,
              seriesThreshold: 1,
            },
            chart: {
              id: "navigator",
              height: 29,
              width: width + 20,
              spacing: [0, 0, 0, 5],
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.text.primary,
            },
            navigator: {
              enabled: true,
              height: 15,
              outlineColor: theme.palette.text.primary,
              maskFill: hexToRgb(`${theme.palette.graph.orange}80`),
              xAxis: {
                width,
                labels: {
                  style: {
                    color: theme.palette.common.white,
                  },
                },
              },
            },
            scrollbar: {
              height: 10,
              trackBackgroundColor: theme.palette.primary.main,
              barBackgroundColor: theme.palette.graph.orange,
              rifleColor: theme.palette.text.primary,
              buttonBackgroundColor: theme.palette.common.white,
              buttonBorderWidth: 0,
              buttonArrowColor: theme.palette.primary.main,
            },
            navigation: {
              buttonOptions: {
                enabled: false,
              },
            },
            title: {
              text: "",
            },
            rangeSelector: {
              enabled: false,
            },
            credits: {
              enabled: false,
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              enabled: false,
            },
            yAxis: {
              title: null,
              gridLineWidth: 0,
              visible: false,
              labels: {
                enabled: false,
              },
            },
            xAxis: {
              type: "datetime",
              labels: {
                enabled: false,
              },
              tickLength: 0,
              lineWidth: 0,
              minRange: 1,
              max: DateTime.now().endOf("hour").plus({ hours: 1 }).toMillis(),
              events: {
                afterSetExtremes: onNavigatorExtremesUpdated,
              },
            },
            series,
          }}
        />
      </Box>
    );
  }
);

ChartNavigator.displayName = "Consumption.FilterBar";
