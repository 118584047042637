import { defaultTheme } from "@conf/utilifeed.theme";

export const OPT_SIM_MAX_DAYS_IN_FUTURE = 10;

export const ConfigViewTab = {
  overview: "over_view",
  deviations: "deviations",
  electricity: "electricity",
  fuel: "fuel",
};

export const DeviationViewTab = {
  general: "general",
  technical: "technical",
  economic: "economic",
  operational: "operational",
};

export const DefaultConfTab = {
  components: "component",
  electricity: "electricity",
  fuel: "fuel",
  settings: "settings",
};

export const ParameterType = {
  TECHNICAL: "TECHNICAL",
  ECONOMIC: "ECONOMIC",
  CALCULATED: "CALCULATED",
  OPERATIONAL: "OPERATIONAL",
  STATE_INPUT: "STATE_INPUT",
  EXTERNAL: "EXTERNAL",
};

export const ConfigCategory = {
  DEFAULT: "Default",
  OFFICIAL: "Official",
  PRIVATE: "Private",
};

export const PropertyType = {
  MIN_EL_MAX_HEAT: "op_point_min_el_max_heat",
  MAX_EL_MAX_HEAT: "op_point_max_el_max_heat",
  MAX_EL_MIN_HEAT: "op_point_max_el_min_heat",
  MIN_EL_MIN_HEAT: "op_point_min_el_min_heat",
  ENERGY_MAX_OUT: "out",
};

export const ComponentType = {
  SIMPLE_EXCHANGE: "SIMPLE_EXCHANGE",
  CHP: "CHP",
  CHILLER: "CHILLER",
  HOB: "HOB",
  THERMAL_DEMAND: "THERMAL_DEMAND",
  COOLING_DEMAND: "COOLING_DEMAND",
  EXTERNAL_CONNECTION: "EXTERNAL_CONNECTION",
  HP: "HP",
  HEAT_PUMP: "HEAT_PUMP",
  ACCUMULATOR: "ACCUMULATOR",
  ABS_CHILLER: "ABS_CHILLER",
  POOL: "POOL",
  DEFAULT_BUILDING_DEMAND: "DEFAULT_BUILDING_DEMAND",
  DEFAULT_BITES: "DEFAULT_BITES",
  RECOVERING_DEMAND_SIDE_STORAGE: "RECOVERING_DEMAND_SIDE_STORAGE",
  DEMAND_SIDE_STORAGE: "DEMAND_SIDE_STORAGE",
  // These are not components, they dont exist in the backend
  // they are used to group components in the frontend
  TOTAL_DEMAND: "TOTAL_DEMAND",
};

export const CoolingProductionComponents = {
  ABS_CHILLER: "ABS_CHILLER",
  CHILLER: "CHILLER",
};

// Job status enum
export const JobStatus = {
  NOT_STARTED: "NOT_STARTED",
  init: "IN_QUEUE",
  RUNNING: "RUNNING",
  done: "FINISHED",
  error: "ERROR",
};

export const StorageComponentTypes = [
  ComponentType.POOL,
  ComponentType.ACCUMULATOR,
  ComponentType.RECOVERING_DEMAND_SIDE_STORAGE,
  ComponentType.DEMAND_SIDE_STORAGE,
];

export const HEAT_PRODUCING_COMPONENTS = [
  ComponentType.CHP,
  ComponentType.HOB,
  ComponentType.HEAT_PUMP,
  ComponentType.EXTERNAL_CONNECTION,
];

export const COOLING_PRODUCING_COMPONENTS = [ComponentType.ABS_CHILLER, ComponentType.CHILLER];

// TODO: This will likely change in the future, as buildings need both a heat and a cooling demand in a year
export const HEAT_DEMAND_COMPONENTS = [
  ComponentType.THERMAL_DEMAND,
  ComponentType.DEFAULT_BUILDING_DEMAND,
  ComponentType.DEFAULT_BITES,
];

export const COOLING_DEMAND_COMPONENTS = [ComponentType.COOLING_DEMAND];

export const DEMAND_COMPONENTS = [
  ...HEAT_DEMAND_COMPONENTS,
  ...COOLING_DEMAND_COMPONENTS,
  ComponentType.EXTERNAL_CONNECTION,
];

// Result Sections
export const RS_COMBINED_SYSTEM = "combined_system";
export const RS_HEATING_NODE = "heating_node";
export const RS_COOLING_NODE = "cooling_node";

export const RESULT_SECTIONS = new Map([
  [RS_COMBINED_SYSTEM, "text_combined_system"],
  [RS_HEATING_NODE, "text_heating_node"],
  [RS_COOLING_NODE, "text_cooling_node"],
]);

export const TABLE_COLUMNS = [
  {
    id: "name",
    label: "text_configuration_name",
    width: 200,
    align: "left",
    sortEnabled: true,
    translateNs: "production",
  },
  {
    id: "category",
    label: "text_category",
    width: 100,
    align: "center",
    sortEnabled: true,
    translateNs: "production",
  },
  {
    id: "created",
    label: "text_created",
    width: 100,
    align: "right",
    sortEnabled: true,
    translateNs: "production",
  },
  {
    id: "updated",
    label: "text_updated",
    width: 100,
    align: "right",
    sortEnabled: true,
    translateNs: "production",
  },
  {
    id: "actions",
    label: "text_actions",
    width: 300,
    align: "right",
    hideTitle: true,
    translateNs: "production",
  },
];

const { graph } = defaultTheme.palette;
// Temp color. We still need victor for the new color
export const COMPONENT_TYPE_COLOR = new Map([
  [ComponentType.CHP, graph.lightGreen],
  [ComponentType.HOB, graph.lightPink],
  [ComponentType.EXTERNAL_CONNECTION, graph.lightblue],
  [ComponentType.ABS_CHILLER, graph.lightOrange],
  [ComponentType.CHILLER, graph.lightTeal],
  [ComponentType.THERMAL_DEMAND, graph.red],
  [ComponentType.COOLING_DEMAND, graph.purple],
  [ComponentType.ACCUMULATOR, graph.teal],
  [ComponentType.COOLING_TOWERS, graph.orange],
  [ComponentType.DEFAULT_BITES, graph.darkYellow],
  [ComponentType.DEFAULT_BUILDING_DEMAND, graph.pink],
  [ComponentType.ELECTRICITY_PRODUCER, graph.yellow],
  [ComponentType.HEAT_PUMP, graph.green],
  [ComponentType.POOL, graph.green],
  [ComponentType.TOTAL_DEMAND, graph.orange],
]);
