import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Box, Button, Popover, Typography } from "@mui/material";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

import { DATE_FORMAT } from "@config";
import { useInject } from "@hooks";
import { HeadingBar } from "@shared/ui/layout";

import { useConsumption } from "./Consumption.store";
import { ConsumptionDatePicker } from "./ConsumptionDatePicker";
import { consumptionLogger } from "./utils";

const logger = consumptionLogger.getSubLogger({ name: "<FetchRangeSelector/>" });
const DATA_PICKER_VIEW = ["year", "month", "day"];

export const FetchRangeSelector = observer(({ title }) => {
  const { t } = useTranslation(["extendView", "_action"]);
  const { hasPartialData, fetchedRange, setFetchedRange } = useConsumption();
  const { faultDetection } = useInject("faultDetection");

  const [fetchRangePopoverEl, setFetchRangePopoverEl] = useState(null);
  const [dateRangeStart, setDateRangeStart] = useState(fetchedRange?.start);
  const [dateRangeEnd, setDateRangeEnd] = useState(fetchedRange?.end);
  const maxDate = useRef(fetchedRange.end);

  const handleLoadMoreClick = (event) => {
    setFetchRangePopoverEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setFetchRangePopoverEl(null);
    setDateRangeStart(fetchedRange.start);
    setDateRangeEnd(fetchedRange.end);
  };

  const onLoadMoreData = () => {
    const nextDateRangeStart = dateRangeStart.set({ hour: 0 });
    const nextDateRangeEnd = dateRangeEnd.endOf("day").startOf("hour");
    logger.debug(
      "onLoadMoreData - start: %s end: %s",
      String(nextDateRangeStart),
      String(nextDateRangeEnd)
    );
    setFetchedRange(nextDateRangeStart, nextDateRangeEnd, true);
    setFetchRangePopoverEl(null);

    faultDetection.setFromDate(dateRangeStart);
    faultDetection.setToDate(dateRangeEnd);
  };

  const handleRangeStartPick = (dt) => {
    if (!dt || dt?.invalid) return;
    setDateRangeStart(dt);
  };

  const handleRangeEndPick = (dt) => {
    if (!dt || dt?.invalid) return;
    setDateRangeEnd(dt);
  };

  // Sync internal state for inputs with the fetchedRange
  useEffect(() => {
    setDateRangeStart(fetchedRange?.start);
    setDateRangeEnd(fetchedRange?.end);
  }, [fetchedRange?.end, fetchedRange?.start]);

  logger.trace("RENDER");

  return (
    <>
      <HeadingBar
        title={t(title, { ns: "extendView" })}
        addons={[
          {
            action: (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  typography: "h6",
                  "& > * ": {
                    display: "flex",
                    border: "solid",
                    borderColor: "grey.100",
                    borderWidth: "0 1px 0 1px",
                    alignItems: "center",
                    p: 1,
                  },
                  "& > *:last-child": {
                    paddingRight: 0,
                    border: 0,
                  },
                }}
              >
                <Box gap={1}>
                  {t("text_data_from", { ns: "extendView" })}:
                  <span data-testid="fetchedRange-start">
                    {fetchedRange.start?.toFormat(DATE_FORMAT)}
                  </span>
                  {t("text_to")}
                  <span data-testid="fetchedRange-end">
                    {fetchedRange.end?.toFormat(DATE_FORMAT)}
                  </span>
                </Box>
              </Box>
            ),
          },
          {
            action: (
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleLoadMoreClick}
                  disabled={!hasPartialData}
                  data-testid="open-fetch-range-selector-btn"
                >
                  {t("action_load_more_data", { ns: "_action" })}
                </Button>
              </div>
            ),
          },
        ]}
        data-testid="fetchedRange-title"
      />
      <Popover
        open={Boolean(fetchRangePopoverEl)}
        anchorEl={fetchRangePopoverEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            width: "300px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 2,
          }}
        >
          <Typography variant="h6" gap="5px">
            {t("text_available_data", { ns: "extendView" })}:
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: [0, 1],
            }}
          >
            <ConsumptionDatePicker
              value={dateRangeStart}
              onChange={handleRangeStartPick}
              views={DATA_PICKER_VIEW}
              // we have no data to know what is the oldest data we have
              // currently hardcoding to the most meaningful date (if not it goes back to 1900)
              // TODO: set this dynamically once there is backend for it
              minDate={DateTime.fromObject({ year: 1999, month: 1, day: 1 })}
              maxDate={maxDate.current}
              id="fetch-range-start"
            />
            <ConsumptionDatePicker
              value={dateRangeEnd}
              onChange={handleRangeEndPick}
              views={DATA_PICKER_VIEW}
              minDate={dateRangeStart}
              maxDate={maxDate.current}
              id="fetch-range-end"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: [0, 1],
            }}
          >
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={onLoadMoreData}
              data-testid="apply-fetch-range-selector-btn"
            >
              {t("action_load", { ns: "_action" })}
            </Button>
            <Button fullWidth color="primary" variant="contained" onClick={handlePopoverClose}>
              {t("action_cancel", { ns: "_action" })}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
});

FetchRangeSelector.defaultProps = {
  title: "",
};

FetchRangeSelector.propTypes = {
  title: PropTypes.string,
};

FetchRangeSelector.displayName = "Consumption.FetchRangeSelector";
