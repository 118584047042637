export const PLAYWRIGHT_RUN = import.meta.env?.["REACT_APP_PLAYWRIGHT_RUN"] || false;
export const DEFAULT_CURRENCY = "SEK";
export const DEFAULT_NETWORK_TIMEZONE_NAME = "Central European Time";
export const NUMBER_FORMAT = "0,0.00";
export const EPO_CHECK_DURATION = 5000; // in ms

export const GMAPS_KEY = import.meta.env?.["REACT_APP_GMAPS_KEY"] || "";
export const DUMMY_TOKEN = `${import.meta.env?.["REACT_APP_DUMMY_TOKEN"] || "dummy"}`;

export const POSTHOG_HOST = import.meta.env?.["REACT_APP_PUBLIC_POSTHOG_HOST"] || "";
export const POSTHOG_API_KEY = import.meta.env?.["REACT_APP_PUBLIC_POSTHOG_KEY"] || "";
export const POSTHOG_ENABLED = POSTHOG_HOST !== "" && POSTHOG_API_KEY !== "";

export const SENTRY_DSN = import.meta.env?.["REACT_APP_SENTRY_DSN"] || "";
export const SENTRY_ENABLED =
  // Only enable Sentry if we have a DSN set
  SENTRY_DSN !== "" &&
  // CI sets this to null if AWS credentials are not set
  SENTRY_DSN !== "null" &&
  // Only enable Sentry in production builds
  import.meta.env.PROD &&
  // do not enable Sentry for E2E tests
  !import.meta.env?.["REACT_APP_E2E"];
