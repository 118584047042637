import { DateTime } from "luxon";

export type SpeedOption = {
  label: string;
  type: "week" | "month" | "months" | "year";
  value: number;
  disableMonth: number;
};

export const SPEED_OPTIONS: SpeedOption[] = [
  { label: "text_last_week", type: "week", value: 1, disableMonth: 0.25 },
  { label: "text_last_month", type: "month", value: 1, disableMonth: 1 },
  { label: "text_last_3_months", type: "months", value: 3, disableMonth: 3 },
  { label: "text_last_year", type: "year", value: 1, disableMonth: 12 },
];

export type DateRange = {
  start: DateTime;
  end: DateTime;
};

export type SpeedDates = Record<string, DateRange>;

export type SpeedContext = {
  fetchedRange: DateRange;
  startHour: number;
  endHour: number;
};

export function generateSpeedDates(context: SpeedContext): SpeedDates {
  const { fetchedRange, startHour = 8, endHour = 9 } = context;
  const rangeEnd = fetchedRange?.end;
  if (!rangeEnd) return {};

  return SPEED_OPTIONS.reduce((acc, { label, type, value }) => {
    const end = rangeEnd.set({ hour: endHour, minute: 0, second: 0, millisecond: 0 });
    const start = end
      .minus({ [type]: value })
      .set({ hour: startHour, minute: 0, second: 0, millisecond: 0 });
    acc[label] = { start, end };
    return acc;
  }, {} as SpeedDates);
}

export type SpeedState = {
  speedOption: string;
  isSpeedButtonActive: boolean;
};

export type SpeedChangeResult = {
  start: DateTime;
  end: DateTime;
} & SpeedState;

export function handleSpeedOptionChange(
  nextOption: string,
  currentState: SpeedState,
  speedDates: SpeedDates,
  fetchedRange: DateRange
): SpeedChangeResult {
  const isDeactivating = currentState.speedOption === nextOption;
  const newSpeedOption = isDeactivating ? "" : nextOption;

  const { start, end } = isDeactivating ? fetchedRange : speedDates[nextOption] || fetchedRange;

  return {
    speedOption: newSpeedOption,
    isSpeedButtonActive: !isDeactivating,
    start,
    end,
  };
}
