/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { DateTime } from "luxon";

import { logger } from "@core/logger";
import type { BlockDefinition } from "@core/types/common";

import { COLTYPE, UNITS } from "./constants";

export function isBlockValue(value) {
  return (
    value !== undefined &&
    value !== null &&
    value !== "" &&
    value !== "NaN" &&
    // eslint-disable-next-line no-restricted-globals
    !isNaN(value) &&
    value !== Infinity
  );
}

const NO_UNIT = null;
export const UNIT = UNITS;

export const PHYSICAL = {
  name: "name",
  heat_energy: "heat_energy",
  heat: "heat",
  vol: "vol",
  location: "location",
  time: "time",
  literal: "literal",
};

export const UID_TYPE = {
  type: COLTYPE.str,
  physical: PHYSICAL.name,
  unit: NO_UNIT,
};

const YEAR_WEEK_TYPE = {
  type: COLTYPE.yearweek,
  physical: PHYSICAL.time,
  unit: null,
};

const STRING_TYPE = {
  type: COLTYPE.str,
  physical: PHYSICAL.name,
  unit: NO_UNIT,
};
const NUMBER_TYPE = {
  type: COLTYPE.number,
  physical: PHYSICAL.literal,
  unit: NO_UNIT,
};
export const ENERGY_TYPE = {
  type: COLTYPE.number,
  physical: PHYSICAL.heat_energy,
  unit: UNIT.energy,
};
const POWER_TYPE = {
  type: COLTYPE.number,
  physical: PHYSICAL.heat_energy,
  unit: UNIT.heat,
};
export const TEMP_TYPE = {
  type: COLTYPE.number,
  physical: PHYSICAL.heat,
  unit: UNIT.temp,
};
export const VIRTUAL_TEMP_TYPE = {
  type: COLTYPE.virtual,
  valueType: COLTYPE.number,
  physical: PHYSICAL.heat,
  unit: UNIT.temp,
  valueFunction: () => null,
};
export const FLOW_TYPE = {
  type: COLTYPE.number,
  physical: PHYSICAL.vol,
  unit: UNIT.flow,
};
const VOL_TYPE = {
  type: COLTYPE.number,
  physical: PHYSICAL.vol,
  unit: UNIT.vol,
};

const GPS_TYPE = {
  type: COLTYPE.coord,
  physical: PHYSICAL.location,
  unit: NO_UNIT,
};

const DATETIME_TYPE = {
  type: COLTYPE.datetime_s,
  physical: PHYSICAL.time,
  unit: UNIT.string,
};

const TIMESTAMP_TYPE = {
  type: COLTYPE.ts_s,
  physical: PHYSICAL.time,
  unit: UNIT.string,
};

const YEAR_TYPE = {
  type: COLTYPE.number,
  physical: PHYSICAL.time,
  unit: NO_UNIT,
};

const YEAR_MONTH_TYPE = {
  type: COLTYPE.yearmonth,
  physical: PHYSICAL.time,
  unit: NO_UNIT,
};
const DATESTRN_TYPE = {
  type: COLTYPE.datestrn,
  physical: PHYSICAL.time,
  unit: UNIT.string,
};
const TZ_TYPE = {
  type: COLTYPE.timezone,
  physical: PHYSICAL.literal,
  unit: NO_UNIT,
};

const JSON_ARRAY_TYPE = {
  type: COLTYPE.json,
  physical: PHYSICAL.literal,
  unit: NO_UNIT,
};

const VIRTUAL_JSON_OBJECT_TYPE = {
  type: COLTYPE.virtual,
  valueType: COLTYPE.json,
  physical: PHYSICAL.literal,
  unit: NO_UNIT,
  valueFunction: (val) => JSON.parse(val),
};

const SIGNATURE_TYPE = {
  ...VIRTUAL_JSON_OBJECT_TYPE,
  valueFunction: (row, col, val) => {
    const valArray = val?.length ? val.split(",") : [];
    return {
      a: parseFloat(valArray[0]),
      b: parseFloat(valArray[1]),
      t0: parseFloat(valArray[2]),
      r2: parseFloat(valArray[3]),
    };
  },
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function DELTATEMP_TYPE(initial, final) {
  return {
    ...VIRTUAL_TEMP_TYPE,
    initial,
    final,
    initialId: undefined,
    finalId: undefined,
    valueFunction(row, col) {
      this.initialId = this.initialId || col.indexOf(this.initial);
      this.finalId = this.finalId || col.indexOf(this.final);
      return row[this.finalId] - row[this.initialId];
    },
  };
}

export function heatEnergyBalance(heat, vol, st, rt) {
  const RHO = 980.3244;
  const CP_WATER = 0.001163205;

  return Math.abs(heat - vol * (st - rt) * RHO * CP_WATER);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function HEAT_ENERGY_BALANCE_TYPE(heatenergy, volume, supplytemp, returntemp) {
  return {
    ...VIRTUAL_TEMP_TYPE,
    idxMap: {},
    valueFunction(row, col) {
      const idx = this.idxMap;
      idx[volume] = idx[volume] || col.indexOf(volume);
      idx[heatenergy] = idx[heatenergy] || col.indexOf(heatenergy);
      idx[supplytemp] = idx[supplytemp] || col.indexOf(supplytemp);
      idx[returntemp] = idx[returntemp] || col.indexOf(returntemp);

      return heatEnergyBalance(
        row[idx[heatenergy]],
        row[idx[volume]],
        row[idx[supplytemp]],
        row[idx[returntemp]]
      );
    },
  };
}

export function getValueFromBlock(
  blocks: Record<string, BlockDefinition>,
  block: string,
  resourceId?: string,
  column?: string
): string & number & boolean {
  const dblock = blocks[block];
  if (dblock && resourceId && column) {
    const rowId = dblock.idxMap.get(resourceId);
    if (rowId === undefined || rowId === null) {
      return undefined;
    }
    const row = dblock.data[rowId as number];
    const col = dblock.columns[column];
    if (col) {
      const colId = col.idx;
      return row[colId];
    }
    return undefined;
  }
  return undefined;
}

export function updateStoreValueFromBlock(
  store,
  attr,
  blocks,
  block,
  resourceId,
  column,
  defaultValue
) {
  const temp = getValueFromBlock(blocks, block, resourceId, column);
  if (temp) {
    store[attr] = temp;
  } else {
    store[attr] = defaultValue;
  }
}

export function toBlkMonth(month) {
  return Number.parseInt(month).toString().padStart(2, "0");
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function generate_block_definition({
  name,
  index,
  rgx,
  to_block_name,
  columns,
  parse_block_name,
  col,
  colNames,
}) {
  const blockDef = {
    index,
    name,
    to_block_name,
    columns,
    col: col || {},
    colNames: colNames || {},
    get_value: getValueFromBlock,
  };

  if (parse_block_name) {
    blockDef.parse_block_name = parse_block_name;
  } else {
    blockDef.parse_block_name = function parseBlockName(blockName) {
      const res = rgx.exec(blockName);

      if (res) {
        const ret = {
          blk: name,
        };
        for (const matchKey of Object.keys(res.groups)) {
          ret[matchKey] = res.groups[matchKey];
        }
        return ret;
      }
      return null;
    };
  }
  const presentCols = Object.keys(blockDef.col);
  for (const colName of Object.keys(columns)) {
    if (presentCols.indexOf(colName) < 0) {
      blockDef.col[colName] = colName;
    }
  }
  const presentNames = new Set(Object.keys(blockDef.colNames));
  for (const colName of Object.keys(columns)) {
    if (!presentNames.has(colName)) {
      blockDef.colNames[colName] = colName;
    }
  }
  return blockDef;
}

// Block Definitions

export const CORE_ROLLING_YEARLY_BLOCK = generate_block_definition({
  name: "core_rolling_yearly",
  index: "uid",
  rgx: /^(?<blkname>core_rolling1Y)(\$(?<col>.*)|)$/,
  to_block_name() {
    return `core_rolling1Y`;
  },
  columns: {
    heat_energy_sum: ENERGY_TYPE,
    volume_sum: VOL_TYPE,
    returntemp_flowweighted_avg: TEMP_TYPE,
    returntemp_powerweighted_avg: TEMP_TYPE,
    returntemp_unweighted_avg: TEMP_TYPE,
    supplytemp_flowweighted_avg: TEMP_TYPE,
    supplytemp_powerweighted_avg: TEMP_TYPE,
    supplytemp_unweighted_avg: TEMP_TYPE,
    deltatemp_flowweighted_avg: DELTATEMP_TYPE(
      "returntemp_flowweighted_avg",
      "supplytemp_flowweighted_avg"
    ),
    deltatemp_powerweighted_avg: DELTATEMP_TYPE(
      "returntemp_powerweighted_avg",
      "supplytemp_powerweighted_avg"
    ),
    deltatemp_unweighted_avg: DELTATEMP_TYPE(
      "returntemp_unweighted_avg",
      "supplytemp_unweighted_avg"
    ),
    heat_energy_balance: HEAT_ENERGY_BALANCE_TYPE(
      "heat_energy_sum",
      "volume_sum",
      "supplytemp_flowweighted_avg",
      "returntemp_flowweighted_avg"
    ),
  },
});

export const CORE_ROLLING_WEEKLY_BLOCK = generate_block_definition({
  name: "core_rolling_weekly",
  index: "uid",
  rgx: /^(?<blkname>core_rolling1Wminus(?<week>\d{1})W)(\$(?<col>.*)|)$/,
  to_block_name({ week = 0 }) {
    return `core_rolling1Wminus${week}W`;
  },
  columns: {
    heat_energy_sum: ENERGY_TYPE,
    volume_sum: VOL_TYPE,
    returntemp_flowweighted_avg: TEMP_TYPE,
    returntemp_powerweighted_avg: TEMP_TYPE,
    returntemp_unweighted_avg: TEMP_TYPE,
    supplytemp_flowweighted_avg: TEMP_TYPE,
    supplytemp_powerweighted_avg: TEMP_TYPE,
    supplytemp_unweighted_avg: TEMP_TYPE,
    deltatemp_flowweighted_avg: DELTATEMP_TYPE(
      "returntemp_flowweighted_avg",
      "supplytemp_flowweighted_avg"
    ),
    deltatemp_powerweighted_avg: DELTATEMP_TYPE(
      "returntemp_powerweighted_avg",
      "supplytemp_powerweighted_avg"
    ),
    deltatemp_unweighted_avg: DELTATEMP_TYPE(
      "returntemp_unweighted_avg",
      "supplytemp_unweighted_avg"
    ),
  },
});

export const ENERGY_SIGNATURE_BLOCK = generate_block_definition({
  name: "energy_signature",
  index: "uid",
  rgx: /^(?<blkname>esig_(?<year>\d{4}))(\$(?<col>.*)|)$/,
  to_block_name({ year }) {
    return `esig_${year}`;
  },
  columns: {
    changepoint_heat_energy: SIGNATURE_TYPE,
    changepoint_volume: SIGNATURE_TYPE,
    changepoint_returntemp: SIGNATURE_TYPE,
    changepoint_supplytemp: SIGNATURE_TYPE,
  },
});

export const CORE_BLOCK = generate_block_definition({
  name: "core",
  index: "uid",
  rgx: /^(?<blkname>core_(?<year>\d{4}).*(?<month>\d{2})?)(\$(?<col>.*)|)$/,
  to_block_name({ year, month }) {
    if (month !== undefined && month !== null) {
      return `core_${year}-${toBlkMonth(month)}`;
    }
    return `core_${year}`;
  },
  columns: {
    heat_energy_sum: ENERGY_TYPE,
    volume_sum: VOL_TYPE,
    returntemp_flowweighted_avg: TEMP_TYPE,
    returntemp_powerweighted_avg: TEMP_TYPE,
    returntemp_unweighted_avg: TEMP_TYPE,
    supplytemp_flowweighted_avg: TEMP_TYPE,
    supplytemp_powerweighted_avg: TEMP_TYPE,
    supplytemp_unweighted_avg: TEMP_TYPE,
    deltatemp_flowweighted_avg: DELTATEMP_TYPE(
      "returntemp_flowweighted_avg",
      "supplytemp_flowweighted_avg"
    ),
    deltatemp_powerweighted_avg: DELTATEMP_TYPE(
      "returntemp_powerweighted_avg",
      "supplytemp_powerweighted_avg"
    ),
    deltatemp_unweighted_avg: DELTATEMP_TYPE(
      "returntemp_unweighted_avg",
      "supplytemp_unweighted_avg"
    ),
    heat_energy_balance: HEAT_ENERGY_BALANCE_TYPE(
      "heat_energy_sum",
      "volume_sum",
      "supplytemp_flowweighted_avg",
      "returntemp_flowweighted_avg"
    ),
  },
  colNames: {
    heat_energy_sum: "Energy(kWh)",
    volume_sum: "Volume(m³)",
    supplytemp_flowweighted_avg: "Supply Temp FlowWeighted(°C)",
    supplytemp_powerweighted_avg: "Supply Temp PowerWeighted(°C)",
    supplytemp_unweighted_avg: "Supply Temp UnWeighted(°C)",
    returntemp_flowweighted_avg: "Return Temp FlowWeighted(°C)",
    returntemp_powerweighted_avg: "Return Temp PowerWeighted(°C)",
    returntemp_unweighted_avg: "Return Temp UnWeighted(°C)",
    deltatemp_flowweighted_avg: "Delta Temp FlowWeighted(°C)",
    deltatemp_powerweighted_avg: "Delta Temp PowerWeighted(°C)",
    deltatemp_unweighted_avg: "Delta Temp UnWeighted(°C)",
  },
});

export const INSTALL_ADDRESS_BLOCK = generate_block_definition({
  name: "install_address",
  index: "uid",
  rgx: /^(?<blkname>(?<blk>install_address))(\$(?<col>.*)|)$/,
  to_block_name() {
    return "install_address";
  },
  columns: {
    street: STRING_TYPE,
    street_name: STRING_TYPE,
    district: STRING_TYPE,
    zip: STRING_TYPE,
    region: STRING_TYPE,
    coordinates: GPS_TYPE,
    coordinates_quality: STRING_TYPE,
  },
});

export const EPCORE_NORMALIZED_BLOCK = generate_block_definition({
  name: "epcore_normalized",
  index: "uid",
  rgx: /^(?<blkname>ep_(?<year>\d{4})-(?<month>\d{2})_core_1000)(\$(?<col>.*)|)$/,
  to_block_name({ year, month }) {
    if (month !== undefined && month !== null) {
      return `ep_${year}-${toBlkMonth(month)}_core_1000`;
    }
    return `ep_${year}-${toBlkMonth(12)}_core_1000`;
  },
  monthFields: ["month"],
  columns: {
    heat_energy_sum: ENERGY_TYPE,
    volume_sum: VOL_TYPE,
    returntemp_flowweighted_avg: TEMP_TYPE,
    returntemp_powerweighted_avg: TEMP_TYPE,
    returntemp_unweighted_avg: TEMP_TYPE,
    supplytemp_flowweighted_avg: TEMP_TYPE,
    supplytemp_powerweighted_avg: TEMP_TYPE,
    supplytemp_unweighted_avg: TEMP_TYPE,
    deltatemp_flowweighted_avg: DELTATEMP_TYPE(
      "returntemp_flowweighted_avg",
      "supplytemp_flowweighted_avg"
    ),
    deltatemp_powerweighted_avg: DELTATEMP_TYPE(
      "returntemp_powerweighted_avg",
      "supplytemp_powerweighted_avg"
    ),
    deltatemp_unweighted_avg: DELTATEMP_TYPE(
      "returntemp_unweighted_avg",
      "supplytemp_unweighted_avg"
    ),
  },
});

export const EPCORE_NORMALIZED_MONTHLY_BLOCK = generate_block_definition({
  name: "epcore_normalized_monthly",
  index: "uid",
  rgx: /^(?<blkname>ep_(?<syear>\d{4})-(?<smonth>\d{2})_core_1000)-(?<month>\d{2})(\$(?<col>.*)|)$/,
  to_block_name({ syear, smonth, month }) {
    return `ep_${syear}-${toBlkMonth(smonth)}_core_1000-${toBlkMonth(month)}`;
  },
  monthFields: ["month", "smonth"],
  columns: {
    returntemp_flowweighted_avg: TEMP_TYPE,
    heat_energy_sum: ENERGY_TYPE,
    volume_sum: VOL_TYPE,
  },
});

export const EPCORE_BLOCK = generate_block_definition({
  name: "epcore",
  index: "uid",
  rgx: /^(?<blkname>ep_(?<syear>\d{4})-(?<smonth>\d{2})_core_(?<year>\d{4})-(?<month>\d{2}))(\$(?<col>.*)|)$/,
  to_block_name({ year, month, syear, smonth }) {
    return `ep_${syear}-${toBlkMonth(smonth)}_core_${year}-${toBlkMonth(month)}`;
  },
  monthFields: ["month", "smonth"],
  columns: {
    returntemp_flowweighted_avg: TEMP_TYPE,
    heat_energy_sum: ENERGY_TYPE,
    volume_sum: VOL_TYPE,
  },
});

export const EP_PEAK_POWER_BLOCK = generate_block_definition({
  name: "ep_peak_power",
  index: "uid",
  rgx: /^(?<blkname>ep_(?<syear>\d{4})-(?<smonth>\d{2})_peak_(?<res>day|hour)_(?<hist>1month|12month)_(?<interval>1|123|345)_(?<year>\d{4})-(?<month>\d{2}))(\$(?<col>.*)|)$/,
  to_block_name({ year, month, res = "day", hist = "1month", interval = "1", syear, smonth }) {
    return `ep_${syear}-${toBlkMonth(smonth)}_peak_${res}_${hist}_${interval}_${year}-${toBlkMonth(
      month
    )}`;
  },
  monthFields: ["month", "smonth"],
  columns: {
    avg_peak_flow: VOL_TYPE,
    avg_peak_power: ENERGY_TYPE,
  },
});

export const AVG_PEAK_1H_BLOCK = generate_block_definition({
  name: "avg_peak_1h",
  index: "uid",
  rgx: /^(?<blkname>peaks_(?<year>\d{4}).*(?<month>\d{2})?)(\$(?<col>.*)|)$/,
  to_block_name({ year, month, isR12 }) {
    if (month !== undefined && month !== null && !isR12) {
      return `peak_hour_1month_1_${year}-${toBlkMonth(month)}`;
    }
    if (isR12) {
      return `peak_hour_12month_1_${year}-${toBlkMonth(month)}`;
    }
    return `peak_hour_12month_1_${year}-12`;
  },
  columns: {
    avg_peak_power: STRING_TYPE,
    avg_peak_flow: STRING_TYPE,
  },
});

export const AVG_PEAK_24H_BLOCK = generate_block_definition({
  name: "avg_peak_24h",
  index: "uid",
  rgx: /^(?<blkname>peaks_(?<year>\d{4}).*(?<month>\d{2})?)(\$(?<col>.*)|)$/,
  to_block_name({ year, month, isR12 }) {
    if (month !== undefined && month !== null && !isR12) {
      return `peak_day_1month_1_${year}-${toBlkMonth(month)}`;
    }
    if (isR12) {
      return `peak_day_12month_1_${year}-${toBlkMonth(month)}`;
    }
    return `peak_day_12month_1_${year}-12`;
  },
  columns: {
    avg_peak_power: STRING_TYPE,
    avg_peak_flow: STRING_TYPE,
  },
});

export const EP_DESIGN_1H_BLOCK = generate_block_definition({
  name: "ep_design_1h",
  index: "uid",
  rgx: /^(?<blkname>ep_(?<year>\d{4})-(?<month>\d{2})_design_1h)(\$(?<col>.*)|)$/,
  to_block_name({ year, month }) {
    if (month) {
      return `ep_${year}-${toBlkMonth(month)}_design_1h`;
    }
    return `ep_${year}-${toBlkMonth(12)}_design_1h`;
  },
  columns: {
    date: DATESTRN_TYPE,
    heat_energy: POWER_TYPE,
    volume: FLOW_TYPE,
    returntemp: TEMP_TYPE,
    outtemp: TEMP_TYPE,
    winddir: STRING_TYPE,
    windspeed: NUMBER_TYPE,
    solar: NUMBER_TYPE,
  },
});

export const EP_DESIGN_24H_BLOCK = generate_block_definition({
  name: "ep_design_24h",
  index: "uid",
  rgx: /^(?<blkname>ep_(?<year>\d{4})-(?<month>\d{2})_design_24h)(\$(?<col>.*)|)$/,
  to_block_name({ year, month }) {
    if (month) {
      return `ep_${year}-${toBlkMonth(month)}_design_24h`;
    }
    return `ep_${year}-${toBlkMonth(12)}_design_24h`;
  },
  columns: {
    date: DATESTRN_TYPE,
    heat_energy: POWER_TYPE,
    volume: FLOW_TYPE,
    returntemp: TEMP_TYPE,
    outtemp: TEMP_TYPE,
    winddir: STRING_TYPE,
    windspeed: NUMBER_TYPE,
    solar: NUMBER_TYPE,
  },
});

export const EP_DESIGN_NOFLOWLIMITER_1H_BLOCK = generate_block_definition({
  name: "ep_design_noflowlimiter_1h",
  index: "uid",
  rgx: /^(?<blkname>ep_(?<year>\d{4})-(?<month>\d{2})_design_noflowlimiter_1h)(\$(?<col>.*)|)$/,
  to_block_name({ year, month }) {
    return `ep_${year}-${toBlkMonth(month)}_design_noflowlimiter_1h`;
  },
  columns: {
    volume: FLOW_TYPE,
    heat_energy: POWER_TYPE,
  },
});

export const EP_DESIGN_NOPOWERLIMITER_1H_BLOCK = generate_block_definition({
  name: "ep_design_nopowerlimiter_1h",
  index: "uid",
  rgx: /^(?<blkname>ep_(?<year>\d{4})-(?<month>\d{2})_design_nopowerlimiter_1h)(\$(?<col>.*)|)$/,
  to_block_name({ year, month }) {
    return `ep_${year}-${toBlkMonth(month)}_design_nopowerlimiter_1h`;
  },
  columns: {
    volume: FLOW_TYPE,
    heat_energy: POWER_TYPE,
  },
});

export const EP_SYSTEMDESIGN_1H_BLOCK = generate_block_definition({
  name: "ep_systemdesign_1h",
  index: "uid",
  rgx: /^(?<blkname>ep_(?<year>\d{4})-(?<month>\d{2})_systemdesign_1h)(\$(?<col>.*)|)$/,
  to_block_name({ year, month }) {
    if (month) {
      return `ep_${year}-${toBlkMonth(month)}_systemdesign_1h`;
    }
    return `ep_${year}-${toBlkMonth(12)}_systemdesign_1h`;
  },
  columns: {
    volume: FLOW_TYPE,
    heat_energy: POWER_TYPE,
    returntemp: TEMP_TYPE,
  },
});

export const EP_SYSTEMDESIGN_24H_BLOCK = generate_block_definition({
  name: "ep_systemdesign_24h",
  index: "uid",
  rgx: /^(?<blkname>ep_(?<year>\d{4})-(?<month>\d{2})_systemdesign_24h)(\$(?<col>.*)|)$/,
  to_block_name({ year, month }) {
    if (month) {
      return `ep_${year}-${toBlkMonth(month)}_systemdesign_24h`;
    }
    return `ep_${year}-${toBlkMonth(12)}_systemdesign_24h`;
  },
  columns: {
    volume: FLOW_TYPE,
    heat_energy: POWER_TYPE,
    returntemp: TEMP_TYPE,
  },
});

export const EP_SYSTEMDESIGN_NOFLOWLIMITER_1H_BLOCK = generate_block_definition({
  name: "ep_systemdesign_noflowlimiter_1h",
  index: "uid",
  rgx: /^(?<blkname>ep_(?<year>\d{4})-(?<month>\d{2})_systemdesign_noflowlimiter_1h)(\$(?<col>.*)|)$/,
  to_block_name({ year, month }) {
    return `ep_${year}-${toBlkMonth(month)}_systemdesign_noflowlimiter_1h`;
  },
  columns: {
    volume: FLOW_TYPE,
    heat_energy: POWER_TYPE,
  },
});

export const EP_SYSTEMDESIGN_NOPOWERLIMITER_1H_BLOCK = generate_block_definition({
  name: "ep_systemdesign_nopowerlimiter_1h",
  index: "uid",
  rgx: /^(?<blkname>ep_(?<year>\d{4})-(?<month>\d{2})_systemdesign_nopowerlimiter_1h)(\$(?<col>.*)|)$/,
  to_block_name({ year, month }) {
    return `ep_${year}-${toBlkMonth(month)}_systemdesign_nopowerlimiter_1h`;
  },
  columns: {
    volume: FLOW_TYPE,
    heat_energy: POWER_TYPE,
  },
});

export const MEASURED_PEAK_POWER_BLOCK = generate_block_definition({
  name: "measured_peak_power",
  index: "uid",
  rgx: /^(?<blkname>peak_(?<res>day|hour)_(?<hist>1month|12month)_(?<interval>1|123|345)_(?<year>\d{4})-(?<month>\d{2}))(\$(?<col>.*)|)$/,
  to_block_name({ year, month, res = "day", hist = "1month", interval = "1" }) {
    return `peak_${res}_${hist}_${interval}_${year}-${toBlkMonth(month)}`;
  },
  monthFields: ["month"],
  columns: {
    avg_peak_flow: VOL_TYPE,
    avg_peak_power: ENERGY_TYPE,
    flow_date_peak_power: ENERGY_TYPE,
  },
});

export const LOCATION_BLOCK = generate_block_definition({
  name: "location",
  index: "uid",
  rgx: /^(?<blkname>location)(\$(?<col>.*)|)$/,
  to_block_name() {
    return "location";
  },
  columns: {
    time_zone: STRING_TYPE,
    street_name: STRING_TYPE,
    street: STRING_TYPE,
    grid_area: STRING_TYPE,
    street_nr: STRING_TYPE,
    coordinates: GPS_TYPE,
    weather_coordinates: GPS_TYPE,
  },
});

export const BUILDING_BLOCK = generate_block_definition({
  name: "building",
  index: "uid",
  rgx: /^(?<blkname>building)(\$(?<col>.*)|)$/,
  to_block_name() {
    return "building";
  },
  columns: {
    customer: STRING_TYPE,
    grid_area: STRING_TYPE,
    street_name: STRING_TYPE,
    year: STRING_TYPE,
    category: STRING_TYPE,
    secondary_heating: STRING_TYPE,
    street_number: STRING_TYPE,
  },
});

export const DISTRIBUTION_BLOCK = generate_block_definition({
  name: "distribution",
  index: "uid",
  rgx: /^(?<blkname>distribution_grid)(\$(?<col>.*)|)$/,
  to_block_name() {
    return "distribution_grid";
  },
  columns: {
    grid_area: STRING_TYPE,
  },
});

export const PRICING_BLOCK = generate_block_definition({
  name: "pricing",
  index: "uid",
  rgx: /^(?<blkname>pricing)(\$(?<col>.*)|)$/,
  to_block_name() {
    return "pricing";
  },
  columns: {
    segment: STRING_TYPE,
    tariff_id: STRING_TYPE,
    power_calc_method: STRING_TYPE,
    price_area: STRING_TYPE,
    flow_limit: NUMBER_TYPE,
    power_limit: NUMBER_TYPE,
    wn_value: NUMBER_TYPE,
  },
});

export const CUSTOMER_BLOCK = generate_block_definition({
  name: "customer",
  index: "uid",
  rgx: /^(?<blkname>customer)(\$(?<col>.*)|)$/,
  to_block_name() {
    return "customer";
  },
  columns: {
    name: STRING_TYPE,
    gdpr_segment: STRING_TYPE,
    id: STRING_TYPE,
    secondary_id: STRING_TYPE,
  },
});

export const BUILDING_ECONOMY = generate_block_definition({
  name: "economy",
  index: "uid",
  rgx: /^(?<blkname>economy)(\$(?<col>.*)|)$/,
  to_block_name() {
    return "economy";
  },
  columns: {
    segment: STRING_TYPE,
  },
});

export const WEATHER_BLOCK = generate_block_definition({
  name: "weather",
  index: "datetime",
  to_block_name() {
    return "weather";
  },
  parse_block_name(blkName) {
    if (blkName === "datetime" || blkName === "t") {
      return { blk: "weather", blkname: "weather", col: blkName };
    }
    return null;
  },
  columns: {
    datetime: DATETIME_TYPE,
    t: TEMP_TYPE,
  },
});

// this cannot be used in addition to other blocks, only infoblocks can be combined
export const EP_SIMULATION_BLOCK = generate_block_definition({
  name: "ep_simulation",
  index: "datetime",
  to_block_name() {
    return "ep_simulation";
  },
  parse_block_name(blkName) {
    return { blk: "ep_simulation", blkname: "ep_simulation", col: blkName };
  },
  columns: {
    datetime: DATETIME_TYPE,
    heat_energy: ENERGY_TYPE,
    heat_energy_lower: ENERGY_TYPE,
    heat_energy_upper: ENERGY_TYPE,
    volume: FLOW_TYPE,
    volume_upper: FLOW_TYPE,
    volume_lower: FLOW_TYPE,
    supplytemp: TEMP_TYPE,
    supplytemp_lower: TEMP_TYPE,
    supplytemp_upper: TEMP_TYPE,
    returntemp: TEMP_TYPE,
    returntemp_lower: TEMP_TYPE,
    returntemp_upper: TEMP_TYPE,
    t: TEMP_TYPE,
  },
});

export const UFINT_LATEST_BLOCK = generate_block_definition({
  name: "ufint_latest",
  index: "uid",
  to_block_name() {
    return "ufint_latest";
  },
  rgx: /^(?<blkname>ufint_latest)(\$(?<col>.*)|)$/,
  columns: {
    ep_month: YEAR_MONTH_TYPE,
    ep_month_clusters: YEAR_MONTH_TYPE,
    core_year: YEAR_TYPE,
    core_month: YEAR_MONTH_TYPE,
    week_nr: YEAR_WEEK_TYPE,
  },
});

export const UFINT_LATEST_FORECAST_BLOCK = generate_block_definition({
  name: "ufint_latest_forecast",
  index: "uid",
  to_block_name() {
    return "ufint_latest_forecast";
  },
  rgx: /^(?<blkname>ufint_latest_forecast)(\$(?<col>.*)|)$/,
  columns: {
    approved_time: DATESTRN_TYPE,
    training_date_min: YEAR_MONTH_TYPE,
    training_date_max: YEAR_MONTH_TYPE,
  },
});

export const LAVA_CALC_BLOCK = generate_block_definition({
  name: "lava_calc",
  index: "uid",
  to_block_name() {
    return "lava_calc";
  },
  rgx: /^(?<blkname>lava_calc)(\$(?<col>.*)|)$/,
  columns: {
    returntemp_target: STRING_TYPE,
    efficiency_value: STRING_TYPE,
  },
});

export const METERING_LATEST_UPLOAD_BLOCK = generate_block_definition({
  name: "metering_latest_upload",
  index: "uid",
  to_block_name() {
    return "metering_latest_upload";
  },
  rgx: /^(?<blkname>metering_latest_upload)(\$(?<col>.*)|)$/,
  columns: {
    observed_to_processed: STRING_TYPE,
    substations_valid_counts: JSON_ARRAY_TYPE,
    substations_processed_counts: JSON_ARRAY_TYPE,
    updated_at: DATESTRN_TYPE,
    valid_time: DATESTRN_TYPE,
    processed_time: DATESTRN_TYPE,
    average_latest_data: DATESTRN_TYPE,
  },
});

export const SUBSTATION_COUNT_BLOCK = generate_block_definition({
  name: "substation_count",
  index: "uid",
  to_block_name({ year, month }) {
    return `substation_count_${year}-${toBlkMonth(month)}`;
  },
  rgx: /^(?<blkname>substation_count_(?<year>\d{4})-(?<month>\d{2}))(\$(?<col>.*)|)$/,
  columns: {
    active: NUMBER_TYPE,
  },
});

export const NETLOCATION_BLOCK = generate_block_definition({
  name: "location",
  index: "uid",
  to_block_name() {
    return "location";
  },
  rgx: /^(?<blkname>location)(\$(?<col>.*)|)$/,
  columns: {
    coordinates: GPS_TYPE,
    weather_coordinates: GPS_TYPE,
    timezone: TZ_TYPE,
    tz_name: STRING_TYPE,
    currency: STRING_TYPE,
  },
});

const METER_DATA_COLS = new Set(["timestamp", "heat_energy", "volume", "supplytemp", "returntemp"]);

export const METER_DATA_BLOCK = generate_block_definition({
  name: "meter_data",
  index: "timestamp",
  to_block_name() {
    return "meter_data";
  },
  parse_block_name(blkName) {
    if (METER_DATA_COLS.has(blkName)) {
      return { blk: "meter_data", blkname: "meter_data", col: blkName };
    }
    return null;
  },
  columns: {
    timestamp: TIMESTAMP_TYPE,
    datetime: DATETIME_TYPE,
    heat_energy: ENERGY_TYPE,
    volume: FLOW_TYPE,
    supplytemp: TEMP_TYPE,
    returntemp: TEMP_TYPE,
  },
});

export const INSTALLATION_BLOCK = generate_block_definition({
  name: "installation",
  index: "uid",
  rgx: /^(?<blkname>(?<blk>installation))(\$(?<col>.*)|)$/,
  to_block_name() {
    return "installation";
  },
  columns: {
    secondary_id: STRING_TYPE,
  },
});

export const HOTWATER_USE_BLOCK = generate_block_definition({
  name: "hotwater_use",
  index: "uid",
  rgx: /^(?<blkname>hotwater_use_(?<year>\d{4}))(\$(?<col>.*)|)$/,
  to_block_name({ year }) {
    return `hotwater_use_${year}`;
  },
  columns: {
    hourly_average_heat_energy: POWER_TYPE,
    yearly_estimate_heat_energy: ENERGY_TYPE,
    hourly_average_volume: FLOW_TYPE,
    yearly_estimate_volume: VOL_TYPE,
  },
});

export const ESIG_CHANGEPOINT_HEAT_ENERGY = generate_block_definition({
  name: "esig_changepoint_heat_energy",
  index: "uid",
  rgx: /^(?<blkname>esig_changepoint_heat_energy_(?<year>\d{4}))(\$(?<col>.*)|)$/,
  to_block_name({ year }) {
    return `esig_changepoint_heat_energy_${year}`;
  },
  columns: {
    changepoint_heat_energy: JSON_ARRAY_TYPE,
  },
});

export const ESIG_CHANGEPOINT_VOLUME = generate_block_definition({
  name: "esig_changepoint_volume",
  index: "uid",
  rgx: /^(?<blkname>esig_changepoint_volume_(?<year>\d{4}))(\$(?<col>.*)|)$/,
  to_block_name({ year }) {
    return `esig_changepoint_volume_${year}`;
  },
  columns: {
    changepoint_volume: JSON_ARRAY_TYPE,
  },
});

export const UFINT_LATEST_UPLOAD = generate_block_definition({
  name: "ufint_latest_upload",
  index: "uid",
  rgx: /^(?<blkname>ufint_latest_upload)(\$(?<col>.*)|)$/,
  to_block_name() {
    return "ufint_latest_upload";
  },
  columns: {
    processed_time: DATESTRN_TYPE,
  },
});

export const PRICING_SPECIFIC_BLOCK = generate_block_definition({
  // ep_2023-12_pricing_2023-01
  name: "pricing_specific",
  index: "uid",
  rgx: /^(?<blkname>ep_(?<year>\d{4})-(?<month>\d{2})_pricing_(?<syear>\d{4})-(?<smonth>\d{2}))(?<col>.*)$/,
  to_block_name({ year, month, smonth, syear }) {
    return `ep_${year}-${toBlkMonth(month)}_pricing_${syear}-${toBlkMonth(smonth)}`;
  },
  columns: {
    day_energy_hours: STRING_TYPE,
    heat_energy_sum_high: STRING_TYPE,
    heat_energy_sum_low: STRING_TYPE,
    heat_power_sig_daily: JSON_ARRAY_TYPE,
    heat_power_sig_hourly: JSON_ARRAY_TYPE,
    night_energy_hours: STRING_TYPE,
    weekend_energy_hours: STRING_TYPE,
  },
});

export const BLOCK = {
  core_monthly: CORE_BLOCK,
  core_yearly: CORE_BLOCK,
  core: CORE_BLOCK,
  install_address: INSTALL_ADDRESS_BLOCK,
  epcore: EPCORE_BLOCK,
  epcore_normalized: EPCORE_NORMALIZED_BLOCK,
  epcore_normalized_monthly: EPCORE_NORMALIZED_MONTHLY_BLOCK,
  ep_peak_power: EP_PEAK_POWER_BLOCK,
  measured_peak_power: MEASURED_PEAK_POWER_BLOCK,
  location: LOCATION_BLOCK,
  avg_peak_1h: AVG_PEAK_1H_BLOCK,
  building: BUILDING_BLOCK,
  customer: CUSTOMER_BLOCK,
  economy: BUILDING_ECONOMY,
  pricing: PRICING_BLOCK,
  distribution: DISTRIBUTION_BLOCK,
  metering_latest_upload: METERING_LATEST_UPLOAD_BLOCK,
  substation_count: SUBSTATION_COUNT_BLOCK,
  core_rolling_yearly: CORE_ROLLING_YEARLY_BLOCK,
};

export const SUBSTATION_BLOCK_TYPES = {
  core: CORE_BLOCK,
  location: LOCATION_BLOCK,
  core_rolling_yearly: CORE_ROLLING_YEARLY_BLOCK,
  core_rolling_weekly: CORE_ROLLING_WEEKLY_BLOCK,
  install_address: INSTALL_ADDRESS_BLOCK,
  epcore_normalized: EPCORE_NORMALIZED_BLOCK,
  epcore_normalized_monthly: EPCORE_NORMALIZED_MONTHLY_BLOCK,
  epcore: EPCORE_BLOCK,
  ep_peak_power: EP_PEAK_POWER_BLOCK,
  avg_peak_1h: AVG_PEAK_1H_BLOCK,
  avg_peak_24h: AVG_PEAK_24H_BLOCK,
  ep_design_1h: EP_DESIGN_1H_BLOCK,
  ep_design_24h: EP_DESIGN_24H_BLOCK,
  ep_design_noflowlimiter_1h: EP_DESIGN_NOFLOWLIMITER_1H_BLOCK,
  ep_design_nopowerlimiter_1h: EP_DESIGN_NOPOWERLIMITER_1H_BLOCK,
  ep_systemdesign_1h: EP_SYSTEMDESIGN_1H_BLOCK,
  ep_systemdesign_24h: EP_SYSTEMDESIGN_24H_BLOCK,
  ep_systemdesign_noflowlimiter_1h: EP_SYSTEMDESIGN_NOFLOWLIMITER_1H_BLOCK,
  ep_systemdesign_nopowerlimiter_1h: EP_SYSTEMDESIGN_NOPOWERLIMITER_1H_BLOCK,
  measured_peak_power: MEASURED_PEAK_POWER_BLOCK,
  building: BUILDING_BLOCK,
  distribution: DISTRIBUTION_BLOCK,
  pricing: PRICING_BLOCK,
  customer: CUSTOMER_BLOCK,
  economy: BUILDING_ECONOMY,
  weather: WEATHER_BLOCK,
  meter_data: METER_DATA_BLOCK,
  ufint_latest_forecast: UFINT_LATEST_FORECAST_BLOCK,
  energy_signature: ENERGY_SIGNATURE_BLOCK,
  esig_changepoint_heat_energy: ESIG_CHANGEPOINT_HEAT_ENERGY,
  esig_changepoint_volume: ESIG_CHANGEPOINT_VOLUME,
  installation: INSTALLATION_BLOCK,
  hotwater_use: HOTWATER_USE_BLOCK,
  ufint_latest_upload: UFINT_LATEST_UPLOAD,
  pricing_specific: PRICING_SPECIFIC_BLOCK,
  // ep_simulation:EP_SIMULATION_BLOCK  # cannot be joined to automatically parse
};

export const NETWORK_BLOCK_TYPES = {
  location: NETLOCATION_BLOCK,
  ufint_latest: UFINT_LATEST_BLOCK,
  lava_calc: LAVA_CALC_BLOCK,
  metering_latest_upload: METERING_LATEST_UPLOAD_BLOCK,
  substation_count: SUBSTATION_COUNT_BLOCK,
};

/**
 * collectorBlockPath
 *
 * @export
 * @param {*} base
 * @param {*} destKey
 * @param {*} value
 * @return {*}
 */
export function collectorBlockPath(base, destKey, value) {
  const [block, col] = destKey.split(".") || [];

  if (!base) base = {};

  if (col !== undefined) {
    // is nested?
    if (!base[block]) {
      base[block] = {};
    }
    base[block][`${col}`] = value;
    return base;
  }

  base[destKey] = value;
  return base;
}

/**
 * collectorJson
 *
 * @param {*} base
 * @param {*} destKey
 * @param {*} value
 * @return {*}
 */
function collectorJson(base, destKey, value) {
  if (!base) {
    return { [`${destKey}`]: value };
  }
  base[destKey] = value;
  return base;
}

/**
 * collectorJsonSum
 *
 * @export
 * @param {*} base
 * @param {*} destKey
 * @param {*} value
 * @return {*}
 */
export function collectorJsonSum(base, destKey, value) {
  if (!base) {
    return { [`${destKey}`]: value };
  }
  base[destKey] = base[destKey] ? base[destKey] + value : value;
  return base;
}

/**
 * collectorArray
 *
 * @export
 * @param {*} base
 * @param {*} destKey
 * @param {*} value
 * @return {*}
 */
export function collectorArray(base, destKey, value) {
  if (!base) {
    return [value];
  }
  base.push(value);
  return base;
}

/**
 * Info-block reader utility
 *
 * Index of first column in spec will be considered.
 * primary block would be first column.
 * returns a reader function that get values for index as json object
 *
 * colspec = [
 *     [type, spec, dest_attr_name]
 * ]
 *
 * type can be function, name ..
 * for type name: spec is of form [block_name, column_name]
 * for type derive : spec is function(current processed row json)=>value
 *
 * @export
 * @param {Object} blocks
 * @param {Array} [colspecs=[]]
 * @param {Function} [collectorFn=collectorJson]
 * @return {Function} reader function
 */
export function blkReader(
  blocks,
  colspecs: Array<[string, unknown, string]> = [],
  collectorFn = collectorJson
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (indexValue: string | number) => null | { [key: string]: any } {
  const parseSpec = [];
  colspecs.forEach(([specType, spec, dest]) => {
    switch (specType) {
      case "name": {
        const [blockName, columnName] = spec;
        const blk = blocks[blockName];
        if (blk?.columns && blk.idxMap) {
          const colSpec = blk.columns[columnName];
          if (colSpec) {
            parseSpec.push(["name", { blk, col: colSpec }, dest]);
          } else {
            logger.warn(
              `column "${columnName}" not found in block "${blockName}", valid columns: ${Object.keys(
                blk.columns
              )}`
            );
            parseSpec.push(["pass", undefined, dest]);
          }
        } else {
          logger.warn(`block "${blockName}" not found, valid blocks: ${Object.keys(blocks)}`);
          parseSpec.push(["pass", undefined, dest]);
        }
        break;
      }
      case "derive":
        parseSpec.push(["derive", spec, dest]);
        break;
      default:
        throw Error("spec type not supported");
    }
  });

  return function reader(indexValue) {
    let row = null;
    parseSpec.forEach(([specType, spec, dest]) => {
      let val;
      switch (specType) {
        case "name": {
          const rowId = spec.blk.idxMap.get(indexValue);
          if (rowId >= 0) {
            val = spec.blk.data[rowId][spec.col.idx];
          }
          break;
        }
        case "derive":
          val = spec(row, indexValue);
          break;
        default:
          break;
      }
      row = collectorFn(row, dest, val);
    });
    return row;
  };
}

/*
  works for single resource_id only.
*/
// eslint-disable-next-line sonarjs/cognitive-complexity
export function transformMeterDataToV3Block({ resp, ts_start, ts_end, components }) {
  const blkName = "meter_data";
  const componentArray = resp?.collection || [];
  const startTime = ts_start.toMillis();
  const endTime = ts_end.toMillis();
  const idx = new Map();
  let ctime = startTime;
  const rowData = [];
  while (ctime < endTime) {
    rowData.push([ctime]);
    idx.set(ctime, rowData.length - 1);
    ctime += 60 * 60 * 1000;
  }
  let colIndex = 0;
  const block = {
    columns: {
      ts: {
        col: "ts",
        idx: colIndex,
        blk: blkName,
        blkName,
        spec: TIMESTAMP_TYPE,
      },
    },
    data: rowData,
    idx: "ts",
    blk: blkName,
    blkName,
    idxMap: idx,
  };
  colIndex += 1;
  for (const cmp of componentArray) {
    if (components.indexOf(cmp.component) >= 0) {
      block.columns[cmp.component] = {
        col: cmp.component,
        idx: colIndex,
        blk: blkName,
        blkName,
        spec: METER_DATA_BLOCK.columns[cmp.component],
      };
      colIndex += 1;
      const { data } = cmp;
      const processedTs = new Set();
      for (const rd of data) {
        let ts = DateTime.fromISO(rd.datetime, { zone: "UTC+00:00" });
        ts = ts.isValid ? ts.toMillis() : null;
        if (ts !== null && idx.has(ts)) {
          processedTs.add(ts);
          const row = block.data[idx.get(ts)];
          if (isBlockValue(rd.value)) {
            row.push(rd.value);
          } else {
            // eslint-disable-next-line no-console
            console.error(`${rd.value} is not a value`);
            row.push(undefined);
          }
        }
      }
      for (const [idxTs, rowPos] of idx) {
        if (!processedTs.has(idxTs)) {
          block.data[rowPos].push(undefined);
        }
      }
    }
  }
  return block;
}

export const rowsHasValidColumns = (columnNames) => {
  const validColumnNames = columnNames.filter(Boolean);
  return (row) => {
    if (!row) {
      return false;
    }
    for (const colName of validColumnNames) {
      if (row[colName] === undefined) {
        return false;
      }
    }
    return true;
  };
};

export function pushUniqueColToSpec() {
  const spec = [];
  const uniqueMap = new Set();
  return [
    (colSpec) => {
      if (!uniqueMap.has(colSpec[colSpec.length - 1])) {
        spec.push(colSpec);
        uniqueMap.add(colSpec[colSpec.length - 1]);
      }
    },
    spec,
  ];
}

export function extractYearFromBlockName(blockName) {
  try {
    return blockName.split("_")[1].split("-")[0];
  } catch {
    return null;
  }
}
