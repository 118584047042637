/* eslint-disable react/jsx-props-no-spreading */
import { observer } from "mobx-react";
import { DateTime } from "luxon";

import { DATE_FORMAT, DATETIME_FORMAT_DEFAULT, INTERVAL_HOURLY } from "@config";
import { DatePicker } from "@shared/ui/inputs";
import type { UTFDateTimeFieldProps } from "@shared/ui/inputs/UtfDatePicker/DatePicker";

import { useConsumption } from "./Consumption.store";

type ConsumptionDatePickerProps = {
  variant?: string;
  minDate?: DateTime;
  maxDate?: DateTime;
  views?: string[];
  value?: DateTime;
  onChange: (date: DateTime) => void;
  format?: string | null;
  disabled?: boolean;
  classname?: string | null;
  id?: string;
} & UTFDateTimeFieldProps;

export const ConsumptionDatePicker = observer(
  ({
    variant = "standard",
    minDate,
    maxDate,
    views = ["year", "month", "day"],
    value,
    onChange,
    format = null,
    disabled = false,
    classname = null,
    id = "consumption",
    ...props
  }: ConsumptionDatePickerProps) => {
    const { inputProps, ...restProps } = props;
    const { interval } = useConsumption();

    const defaultFormat = interval === INTERVAL_HOURLY ? DATETIME_FORMAT_DEFAULT : DATE_FORMAT;

    return (
      <DatePicker
        minDate={minDate}
        maxDate={maxDate}
        closeOnSelect={false}
        ampm={false}
        views={views}
        value={value}
        onChange={onChange}
        disabled={disabled}
        format={format ?? defaultFormat}
        className={classname}
        inputProps={{
          variant,
          helperText: "",
          InputProps: {
            "data-testid": `date-input__${id}`,
          },
          ...inputProps,
          sx: {
            "& .MuiInput-root": {
              border: "1px solid currentColor",
            },
            ...inputProps?.sx,
          },
        }}
        slotProps={{
          popper: {
            placement: "top",
          },
        }}
        {...restProps}
      />
    );
  }
);

ConsumptionDatePicker.displayName = "Consumption.DatePicker";
