import type { FC } from "react";
import { useMemo } from "react";
import { observer } from "mobx-react";

import { useInject } from "@hooks";
import Base, { PermissionChecker, SectionSelectorProps } from "@shared/ui/layout/SectionSelector";

type MobXSectionSelectorProps = Omit<SectionSelectorProps, "permissionChecker"> & {
  networks?: { haveAccess: (permissions: string[]) => boolean };
};

export const SectionSelector: FC<MobXSectionSelectorProps> = observer((props) => {
  const { networks } = useInject("networks");

  const permissionChecker: PermissionChecker = useMemo(() => {
    return {
      haveAccess: (permissions: string[]) => {
        try {
          return networks.haveAccess(permissions);
        } catch (error) {
          console.error("Error checking permissions:", error);
          return false; // Default to no access in case of error
        }
      },
    };
  }, [networks]);

  return <Base {...props} permissionChecker={permissionChecker} />;
});

SectionSelector.displayName = "UtfSectionSelector";

export default SectionSelector;
