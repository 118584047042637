import {
  CORE_ROLLING_YEARLY_BLOCK,
  ENERGY_TYPE,
  EPCORE_NORMALIZED_BLOCK,
  FLOW_TYPE,
} from "@conf/blocks";
import { COLUMN_SECTION } from "@conf/Columns";
import { coordinatesSort } from "@core/utils/columns/coordinatesSort";
import { iconSort, numberSort } from "@core/utils/columns/utils";

function generateFDCColumnSpec({ accessorKey }: { accessorKey: string }) {
  return {
    type: "number",
    translateNs: "_common",
    size: 180,
    accessorKey,
    path: accessorKey,
    blockName: accessorKey.split(".")[0],
    info: "",
    align: "right",
    spec: {
      type: "str",
      physical: "name",
      unit: null,
    },
    hideable: true,
    isVisible: false,
  };
}

export default [
  //* --CUSTOMER
  {
    id: "name",
    ...generateFDCColumnSpec({ accessorKey: "customer.name" }),
    header: "text_customer_name",
    sortingFn: "alphanumeric",
    section: COLUMN_SECTION.customer,
    type: "string",
    align: "left",
    info: "text_customer_name_desc",
    hideable: false,
    isVisible: true,
  },
  //* -- FAULT_DETECTION
  {
    id: "fault_detection_ignored",
    ...generateFDCColumnSpec({ accessorKey: "fault_detection_ignored" }),
    type: "string",
    align: "left",
    header: "text_fault_detection_relevance",
    sortingFn: "alphanumeric",
    info: "text_fault_detection_relevance_desc",
    translateNs: "metering",
    hideable: false,
    isVisible: true,
  },
  {
    id: "fault_detection_note",
    accessorKey: "note",
    size: 150,
    minSize: 150,
    grow: false,
    type: "svgIcon",
    align: "center",
    header: "text_notes",
    sortingFn: iconSort,
    info: "text_notes_desc",
    translateNs: "_common",
    hideable: false,
    isVisible: true,
  },
  {
    id: "fault_detection",
    accessorKey: "fault_detection",
    header: "text_fd_faults",
    info: "text_fault_detection_fault_desc",
    translateNs: "_common",
    hideable: false,
    isVisible: true,
    enableSorting: false,
    type: "object",
    columns: [
      {
        id: "fault_detection.supplytemp_dipp",
        accessorKey: "fault_detection.supplytemp_dipp",
        header: "text_fd_supplytemperature",
        type: "svgIcon",
        grow: false,
        info: "text_fault_detection_fault_supplytemperature_dipp_desc",
        translateNs: "_common",
        align: "center",
        size: 260,
        minSize: 260,
        sortingFn: iconSort,
        hideable: false,
        isVisible: true,
      },
      {
        id: "fault_detection.Returntemp",
        accessorKey: "fault_detection.Returntemp",
        header: "text_fd_return_temperature",
        type: "svgIcon",
        size: 260,
        minSize: 260,
        info: "text_fault_detection_fault_return_temperature",
        translateNs: "_common",
        align: "center",
        sortingFn: iconSort,
        hideable: false,
        isVisible: true,
      },
      {
        id: "fault_detection.Flow",
        accessorKey: "fault_detection.Flow",
        type: "svgIcon",
        header: "text_flow",
        size: 140,
        minSize: 140,
        grow: false,
        info: "text_fault_detection_fault_flow_desc",
        translateNs: "_common",
        align: "center",
        sortingFn: iconSort,
        hideable: false,
        isVisible: true,
      },
    ],
  },

  //* --PRICING
  {
    id: "flow_limit",
    ...generateFDCColumnSpec({ accessorKey: "pricing.flow_limit" }),
    header: "text_flow_limiter",
    sortingFn: numberSort,
    section: COLUMN_SECTION.customer,
    info: "text_flow_limiter_desc",
  },
  {
    id: "tariff_id",
    ...generateFDCColumnSpec({ accessorKey: "pricing.tariff_id" }),
    header: "text_price_tariff",
    sortingFn: "alphanumeric",
    section: COLUMN_SECTION.customer,
    type: "string",
    align: "left",
    info: "text_price_tariff_desc",
  },

  //* --ENERGY
  {
    id: "ep_heat_energy_sum",
    ...generateFDCColumnSpec({ accessorKey: "epcore_normalized.heat_energy_sum" }),
    blockName: (year, month) => EPCORE_NORMALIZED_BLOCK.to_block_name({ year, month }),
    header: "text_energy_kwh",
    sublabel: "text_normal_year",
    sortingFn: numberSort,
    section: COLUMN_SECTION.energy,
    spec: ENERGY_TYPE,
    info: "text_fd_energy_kwh_normal_year_desc",
  },
  {
    id: "heat_energy_sum",
    ...generateFDCColumnSpec({ accessorKey: "core.heat_energy_sum" }),
    blockName: (year) => CORE_ROLLING_YEARLY_BLOCK.to_block_name(year),
    header: "text_energy_kwh",
    sortingFn: numberSort,
    info: "text_fd_energy_desc",
    section: COLUMN_SECTION.energy,
    isVisible: true,
    spec: ENERGY_TYPE,
  },
  {
    id: "average_heat_energy",
    ...generateFDCColumnSpec({ accessorKey: "core.average_heat_energy" }),
    header: "text_power_kw",
    sublabel: "text_average",
    sortingFn: numberSort,
    info: "text_fd_energy_avg_desc",
    section: COLUMN_SECTION.energy,
    spec: ENERGY_TYPE,
  },

  //* --FLOW
  {
    id: "flow",
    ...generateFDCColumnSpec({ accessorKey: "core.flow" }),
    header: "text_flow_m3_h",
    sublabel: "text_average",
    sortingFn: numberSort,
    info: "text_flow_m3_h_avg_desc",
    section: COLUMN_SECTION.flow,
    spec: FLOW_TYPE,
  },
  {
    id: "volume_sum",
    ...generateFDCColumnSpec({ accessorKey: "core.volume_sum" }),
    blockName: (year) => CORE_ROLLING_YEARLY_BLOCK.to_block_name(year),
    header: "text_volume_m3",
    sortingFn: numberSort,
    info: "text_fd_volume_desc",
    section: COLUMN_SECTION.flow,
    spec: FLOW_TYPE,
    isVisible: true,
  },

  //* --LOCATION
  {
    id: "coordinates",
    ...generateFDCColumnSpec({ accessorKey: "install_address.coordinates" }),
    header: "text_coordinates",
    sortingFn: coordinatesSort,
    info: "text_coordinates_desc",
    section: COLUMN_SECTION.location,
    type: "coord",
    align: "left",
  },
  {
    id: "street",
    ...generateFDCColumnSpec({ accessorKey: "install_address.street_name" }),
    header: "text_install_address",
    sortingFn: "alphanumeric",
    info: "text_install_address_desc",
    section: COLUMN_SECTION.location,
    type: "string",
    align: "left",
  },
  {
    id: "grid_area",
    ...generateFDCColumnSpec({ accessorKey: "distribution_grid.grid_area" }),
    header: "text_grid_area",
    sortingFn: "alphanumeric",
    info: "text_grid_area_desc",
    section: COLUMN_SECTION.location,
    type: "string",
    align: "left",
  },
  {
    id: "zip",
    ...generateFDCColumnSpec({ accessorKey: "install_address.zip" }),
    header: "text_zip_code",
    sortingFn: "alphanumeric",
    info: "text_zip_code_desc",
    section: COLUMN_SECTION.location,
    translateNs: "_common",
    type: "string",
    align: "left",
  },

  //* --TEMPERATURES
  //* Normal Year
  {
    id: "ep_supplytemp_flowweighted_avg",
    ...generateFDCColumnSpec({ accessorKey: "epcore_normalized.supplytemp_flowweighted_avg" }),
    header: "text_supply_temperature_c",
    sublabel: "sub_text_return_temp_c_normal_year",
    blockName: (year, month) => EPCORE_NORMALIZED_BLOCK.to_block_name({ year, month }),
    sortingFn: numberSort,
    section: COLUMN_SECTION.temperatures,
    spec: ENERGY_TYPE,
    info: "sub_text_fd_supply_temp_c_normal_year_desc",
  },
  {
    id: "ep_returntemp_flowweighted_avg",
    ...generateFDCColumnSpec({ accessorKey: "epcore_normalized.returntemp_flowweighted_avg" }),
    header: "text_return_temperature_c",
    sublabel: "sub_text_return_temp_c_normal_year",
    blockName: (year, month) => EPCORE_NORMALIZED_BLOCK.to_block_name({ year, month }),
    sortingFn: numberSort,
    section: COLUMN_SECTION.temperatures,
    spec: ENERGY_TYPE,
    info: "sub_text_fd_return_temp_c_normal_year_desc",
  },
  {
    id: "delta_temp_flowweighted_avg",
    ...generateFDCColumnSpec({ accessorKey: "epcore_normalized.delta_temp_flowweighted_avg" }),
    header: "text_delta_temperature_c",
    sublabel: "sub_text_return_temp_c_normal_year",
    sortingFn: numberSort,
    section: COLUMN_SECTION.temperatures,
    spec: ENERGY_TYPE,
    info: "sub_text_fd_delta_temp_c_normal_year_desc",
  },

  //* Flow Weighted
  {
    id: "supplytemp_flowweighted_avg",
    ...generateFDCColumnSpec({ accessorKey: "core.supplytemp_flowweighted_avg" }),
    header: "text_supply_temperature_c",
    sublabel: "sub_text_supply_temp_c_flow_weighted",
    blockName: (year) => CORE_ROLLING_YEARLY_BLOCK.to_block_name(year),
    sortingFn: numberSort,
    info: "text_fd_supply_temp_flowweighted_desc",
    section: COLUMN_SECTION.temperatures,
    spec: ENERGY_TYPE,
  },
  {
    id: "returntemp_flowweighted_avg",
    ...generateFDCColumnSpec({ accessorKey: "core.returntemp_flowweighted_avg" }),
    header: "text_return_temperature_c",
    sublabel: "sub_text_return_temp_c_flow_weighted",
    blockName: (year) => CORE_ROLLING_YEARLY_BLOCK.to_block_name(year),
    sortingFn: numberSort,
    info: "text_fd_return_temp_flowweighted_desc",
    section: COLUMN_SECTION.temperatures,
    spec: ENERGY_TYPE,
  },
  {
    id: "delta_temp_flow_weighted",
    ...generateFDCColumnSpec({ accessorKey: "core.delta_temp_flow_weighted" }),
    header: "text_delta_temperature_c",
    sublabel: "sub_text_supply_temp_c_flow_weighted",
    sortingFn: numberSort,
    info: "text_fd_delta_temp_flowweighted_desc",
    section: COLUMN_SECTION.temperatures,
    spec: ENERGY_TYPE,
  },

  //* Power Weighted
  {
    id: "supplytemp_powerweighted_avg",
    ...generateFDCColumnSpec({ accessorKey: "core.supplytemp_powerweighted_avg" }),
    header: "text_supply_temperature_c",
    sublabel: "sub_text_supply_temp_c_power_weighted",
    blockName: (year) => CORE_ROLLING_YEARLY_BLOCK.to_block_name(year),
    sortingFn: numberSort,
    info: "text_fd_supply_temp_powerweighted_desc",
    section: COLUMN_SECTION.temperatures,
    spec: ENERGY_TYPE,
  },
  {
    id: "returntemp_powerweighted_avg",
    ...generateFDCColumnSpec({ accessorKey: "core.returntemp_powerweighted_avg" }),
    header: "text_return_temperature_c",
    sublabel: "sub_text_return_temp_c_power_weighted",
    blockName: (year) => CORE_ROLLING_YEARLY_BLOCK.to_block_name(year),
    sortingFn: numberSort,
    info: "text_fd_return_temp_powerweighted_desc",
    section: COLUMN_SECTION.temperatures,
    spec: ENERGY_TYPE,
  },
  {
    id: "delta_temp_power_weighted",
    ...generateFDCColumnSpec({ accessorKey: "core.delta_temp_power_weighted" }),
    header: "text_delta_temperature_c",
    sublabel: "sub_text_supply_temp_c_power_weighted",
    sortingFn: numberSort,
    info: "text_fd_delta_temp_powerweighted_desc",
    section: COLUMN_SECTION.temperatures,
    spec: ENERGY_TYPE,
  },

  //* Unweighted
  {
    id: "supplytemp_unweighted_avg",
    ...generateFDCColumnSpec({ accessorKey: "core.supplytemp_unweighted_avg" }),
    header: "text_supply_temperature_c",
    sublabel: "sub_text_supply_temp_c_unweighted",
    blockName: (year) => CORE_ROLLING_YEARLY_BLOCK.to_block_name(year),
    sortingFn: numberSort,
    info: "text_fd_supply_temp_unweighted_desc",
    section: COLUMN_SECTION.temperatures,
    spec: ENERGY_TYPE,
  },
  {
    id: "returntemp_unweighted_avg",
    ...generateFDCColumnSpec({ accessorKey: "core.returntemp_unweighted_avg" }),
    header: "text_return_temperature_c",
    sublabel: "sub_text_return_temp_c_unweighted",
    blockName: (year) => CORE_ROLLING_YEARLY_BLOCK.to_block_name(year),
    sortingFn: numberSort,
    info: "text_fd_return_temp_unweighted_desc",
    section: COLUMN_SECTION.temperatures,
    spec: ENERGY_TYPE,
  },
  {
    id: "delta_temp_unweighted",
    ...generateFDCColumnSpec({ accessorKey: "core.delta_temp_unweighted" }),
    header: "text_delta_temperature_c",
    sublabel: "sub_text_supply_temp_c_unweighted",
    sortingFn: numberSort,
    info: "text_fd_delta_temp_unweighted_desc",
    section: COLUMN_SECTION.temperatures,
    spec: ENERGY_TYPE,
  },
];
