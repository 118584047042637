/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, ButtonGroup, Divider, Drawer, Button as MuiButton } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import { INTERVAL_HOURLY, INTERVAL_OPTIONS, SPEED_OPTIONS } from "@config";
import Text from "@components/app/Text";
import { useInject } from "@hooks";
import { ButtonDropdown, withErrorBoundary } from "@shared";

import { ChartNavigator } from "./ChartNavigator";
import { useConsumption } from "./Consumption.store";
import { ConsumptionDatePicker } from "./ConsumptionDatePicker";
import { consumptionLogger } from "./utils";

const logger = consumptionLogger.getSubLogger({ name: "<FilterBar/>" });
const DRAWER_WIDTH = "100%";
const ROW_HEIGHT = 56;

const Row = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  width: "100%",
  minHeight: ROW_HEIGHT,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  boxShadow: theme.shadows[1],
  gap: theme.spacing(2),
}));

const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "selected" && prop !== "sx",
})(({ theme, selected }) => ({
  ...theme.typography.button,
  height: theme.spacing(4),
  padding: theme.spacing(1),
  fontSize: "1rem",
  border: `${theme.palette.grey["50"]} 1px solid !important`,
  ...(selected && {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  }),
}));

const ToggleButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1.8),
  boxShadow: theme.shadows[1],
  cursor: "pointer",
  svg: {
    color: "currentcolor",
  },
}));

const Section = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "auto",
  height: "100%",
  alignItems: "center",
  color: theme.palette.grey["50"],
  gap: theme.spacing(1),
}));

const DrawerBody = styled("div")(({ theme }) => ({
  color: "white",
  display: "flex",
  overflow: "hidden",
  backgroundColor: theme.palette.primary.main,
}));

function VerticalDivider() {
  return (
    <Divider orientation="vertical" variant="inset" flexItem sx={{ width: "2px", margin: 0 }} />
  );
}

function openedMixin(theme) {
  return {
    height: `calc(${ROW_HEIGHT} * 2 + 1px)`,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  };
}

function closedMixin(theme) {
  return {
    height: `calc(${ROW_HEIGHT} + 1px)`,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  };
}

function FilterDrawer({ children, ...props }) {
  const theme = useTheme();
  const paperDefaults = { width: DRAWER_WIDTH, boxSizing: "border-box" };
  return (
    <Drawer
      variant="permanent"
      anchor="bottom"
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        position: "fixed", // do not retain a space in the dom
        width: DRAWER_WIDTH,
        flexShrink: 0,
        ...(props.open && {
          ...openedMixin(theme),
          "& .MuiDrawer-paper": {
            ...paperDefaults,
            ...openedMixin(theme),
            "& > div": {
              ...openedMixin(theme),
            },
          },
        }),
        ...(!props.open && {
          ...closedMixin(theme),
          "& .MuiDrawer-paper": {
            ...paperDefaults,
            ...closedMixin(theme),
            "& > div": {
              ...closedMixin(theme),
            },
          },
        }),
      }}
      {...props}
    >
      {children}
    </Drawer>
  );
}

export const FilterBar = withErrorBoundary(
  observer(() => {
    const { ui } = useInject("ui");
    const { t } = useTranslation(["extendView"]);
    const {
      interval,
      onIntervalChange,
      extremes,
      fetchedRange,
      hasPartialData,
      onSpeedOptionChange,
      onExtremeStartInputChange,
      onExtremeEndInputChange,
      speedOption,
      registerChart,
    } = useConsumption();
    const [open, setOpen] = useState(true);
    const navigatorChartRef = useRef();
    const chartWidth = ui.max_drawer_width;

    registerChart("navigator", navigatorChartRef);

    const fetchedRangeStartAndEndDiff = fetchedRange?.end
      ?.diff(fetchedRange.start, ["months"])
      .toObject();

    const DATA_PICKER_VIEW = [
      "year",
      "month",
      "day",
      ...(interval === INTERVAL_HOURLY ? ["hours"] : []),
    ];

    const onExpandToggle = () => {
      setOpen((isOpen) => !isOpen);
    };

    useEffect(() => {
      if (open) {
        ui.setDrawerHeight(`calc(100% - ${ROW_HEIGHT * 2}px)`);
      } else {
        ui.setDrawerHeight(`calc(100% - ${ROW_HEIGHT}px)`);
      }

      return () => {
        ui.setDrawerHeight("100%");
      };
    }, [ui, open]);

    logger.trace("RENDER");

    return (
      <FilterDrawer open={open} data-testid="consumption-filterbar">
        <DrawerBody>
          <Box flex={1}>
            <Row>
              {/* Highcharts with only navigator enabled */}
              <Section minWidth={chartWidth}>
                <ChartNavigator chartRef={navigatorChartRef} width={chartWidth} />
              </Section>
            </Row>

            {/* Second, hideable row */}
            {open && (
              <Row data-testid="consumption-filterbar-hideable">
                {/* Fill the left  */}
                <Box flex={1} />
                <VerticalDivider />
                {/* Intervals */}
                <Section>
                  <Text>{t("text_resolution")} :</Text>
                  <ButtonDropdown
                    options={INTERVAL_OPTIONS}
                    selectedValue={interval}
                    onChange={onIntervalChange}
                    disabled={!hasPartialData}
                    variant="outlined"
                    placement="top"
                    data-testid="interval-btn"
                    translationNs="_common"
                  />
                </Section>
                <VerticalDivider />
                {/* Speed Options */}
                <Section>
                  <ButtonGroup disabled={!hasPartialData}>
                    {SPEED_OPTIONS.map(({ value, label, type, disableMonth }) => (
                      <Button
                        key={`speed-button-${type}`}
                        selected={speedOption === label && hasPartialData}
                        onClick={() => onSpeedOptionChange(label)}
                        disabled={fetchedRangeStartAndEndDiff?.months <= disableMonth}
                        data-testid={`speed-button-${type}`}
                        color="primary"
                        variant="contained"
                      >
                        {t(label)}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Section>
                <VerticalDivider />
                <Section>
                  <ConsumptionDatePicker
                    minDate={fetchedRange?.start}
                    maxDate={fetchedRange?.end}
                    value={extremes?.start}
                    onChange={onExtremeStartInputChange}
                    views={DATA_PICKER_VIEW}
                    disabled={!hasPartialData}
                    inputProps={{ sx: { width: "185px" } }}
                    id="extremes-start"
                  />
                  <Box width={8} height={2} backgroundColor="white" />
                  <ConsumptionDatePicker
                    minDate={fetchedRange?.start}
                    maxDate={fetchedRange?.end}
                    value={extremes?.end}
                    onChange={onExtremeEndInputChange}
                    views={DATA_PICKER_VIEW}
                    disabled={!hasPartialData}
                    inputProps={{ sx: { width: "185px" } }}
                    id="extremes-end"
                  />
                </Section>
              </Row>
            )}
          </Box>
          {/* Expansion Toggler */}
          <ToggleButton onClick={onExpandToggle}>
            <ExpandMoreIcon sx={{ transform: open ? undefined : "rotate(180deg)" }} />
          </ToggleButton>
        </DrawerBody>
      </FilterDrawer>
    );
  })
);

FilterBar.displayName = "Consumption.FilterBar";
