import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { observer } from "mobx-react";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/styles";
import { faFilter, faGlobe, faNetworkWired } from "@fortawesome/pro-light-svg-icons";

import { APP_BAR_HEIGHT } from "@conf/ui_constants";
import SubstationIcon from "@icons/Substation";
import { useInject } from "@hooks";
import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";
import * as CONSTANTS from "@shared/ui/layout/constants";

import { SettingOptions } from "./SettingOptions";

const PREFIX = "PageSetting";

const classes = {
  menuButton: `${PREFIX}-menuButton`,
  menuItem: `${PREFIX}-menuItem`,
  filteredSub: `${PREFIX}-filteredSub`,
  icon: `${PREFIX}-icon`,
  smallIcon: `${PREFIX}-smallIcon`,
};
const MenuButton = styled(Button)(({ theme }) => ({
  height: APP_BAR_HEIGHT,
  display: "flex",
  gap: theme.spacing(1),
  padding: theme.spacing(0, 2.3),
  borderRadius: 0,
  border: 0,
  borderLeft: "1px solid",
  borderColor: theme.palette.border.divider.dark,
  "&:first-child": {
    borderLeft: "1px solid",
    borderColor: theme.palette.border.divider.dark,
  },
  "&:last-child": {
    borderRight: "none",
  },
  color: theme.palette.grey["50"],
  "&:hover": {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.secondary.main,
  },
  pointerEvents: "auto",
  textTransform: "capitalize",
}));

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.menuItem}`]: {
    display: "inherit",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(1),
    "& > svg:nth-child(2)": {
      paddingBottom: theme.spacing(0.6),
    },
  },
  [`& .${classes.filteredSub}`]: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.background.paper,
    },
  },
  [`& .${classes.icon}`]: { width: "20px", height: "20px", fill: "currentColor" },
  [`& .${classes.smallIcon}`]: { width: "17px", height: "17px" },
}));

const minSettingWidth = "96px";
const highlightColor = "secondary.main";

const flagMap: Record<string, string> = {
  en: "GB",
  sv: "SE",
  da: "DK",
};

export const PageSetting = observer(({ filterAffects }) => {
  const { networks, language } = useInject("networks", "language");
  const [settingType, setSettingType] = useState("");
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [isSubstationBtnHover, setSubstationBtnHover] = useState(false);

  const openPopover = Boolean(popoverAnchorEl);
  const popoverId = openPopover ? `${String(settingType)}-popover` : undefined;

  const handleClick = (event, type) => {
    setPopoverAnchorEl(event.currentTarget);
    setSettingType(type);
  };

  const handleClose = () => {
    setPopoverAnchorEl(null);
    // without this it will show a blank screen for like 0.1sec
    setTimeout(() => {
      setSettingType("");
    }, 100);
  };

  const substationsFiltered =
    networks.active_substations.size !== networks.current_substations.size;

  return (
    <Root>
      <Box display="flex" justifyContent="end">
        {/* Language */}
        <MenuButton
          sx={{
            minWidth: minSettingWidth,
            bgcolor: settingType === CONSTANTS.LANGUAGE ? highlightColor : "",
          }}
          onClick={(e) => handleClick(e, CONSTANTS.LANGUAGE)}
          disabled={!networks.ready}
          data-testid={`${CONSTANTS.LANGUAGE}-setting-button`}
        >
          {language.currentLanguage}{" "}
          <ReactCountryFlag
            svg
            countryCode={flagMap[language.currentLanguage] || language.currentLanguage}
            style={{
              fontSize: "1.5em",
              lineHeight: "1.5em",
            }}
          />
        </MenuButton>

        {/* Substation */}
        <MenuButton
          sx={{
            minWidth: minSettingWidth,
            bgcolor: settingType === CONSTANTS.SUBSTATION ? highlightColor : "",
          }}
          onClick={(e) => handleClick(e, CONSTANTS.SUBSTATION)}
          disabled={!networks.ready}
          data-testid={`${CONSTANTS.SUBSTATION}-setting-button`}
          onMouseEnter={() => setSubstationBtnHover(true)}
          onMouseLeave={() => setSubstationBtnHover(false)}
        >
          <Box display="flex" flexDirection="column">
            <div className={classes.menuItem}>
              {substationsFiltered && filterAffects && (
                <>
                  {networks.ready ? (
                    <span
                      className={!isSubstationBtnHover && !settingType && classes.filteredSub}
                      data-testid={`network-${CONSTANTS.SUBSTATION}-filtered-count`}
                    >
                      {networks.active_substations.size}
                    </span>
                  ) : (
                    "..."
                  )}
                  <FontAwesomeSvgIcon icon={faFilter} className={classes.icon} />
                </>
              )}
            </div>
            <div className={classes.menuItem} data-testid={`network-${CONSTANTS.SUBSTATION}-count`}>
              {networks.ready ? networks.current_substations.size : "..."}
              <SubstationIcon className={substationsFiltered ? classes.smallIcon : classes.icon} />
            </div>
          </Box>
        </MenuButton>

        {/* Networks */}
        <MenuButton
          sx={{ bgcolor: settingType === CONSTANTS.NETWORK ? highlightColor : "" }}
          onClick={(e) => handleClick(e, CONSTANTS.NETWORK)}
          disabled={!networks.ready}
          data-testid={`${CONSTANTS.NETWORK}-setting-button`}
        >
          {networks.ready ? networks.current_network.display_name : "..."}
          <FontAwesomeSvgIcon icon={faNetworkWired} className={classes.icon} />
        </MenuButton>
      </Box>

      {/* Content renderer for the selected setting options */}
      <SettingOptions
        id={popoverId}
        open={openPopover}
        settingType={settingType}
        anchorEl={popoverAnchorEl}
        handleClose={handleClose}
      />
    </Root>
  );
});

PageSetting.displayName = "PageSetting";
