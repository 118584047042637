import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/styles";
import { DateTime } from "luxon";

import { DATETIME_FORMAT_DEFAULT } from "@config";
import { TOOLBOX_OPTION_GRAPH_GRID } from "@config/chartToolbox";
import { columns, DIAGRAM_SORT_OPTIONS } from "@config/consumption";
import { formatNumberForLocale, isValue } from "@core/utils";
import { createSeriesToggleHandler } from "@core/utils/createSeriesToggleHandler";
import { generateFlowLimiterPlot } from "@core/utils/generateFlowLimiterPlot";
import {
  ButtonDropdown,
  ChartToolbox,
  GraphContainer,
  HighchartsBase,
  withErrorBoundary,
} from "@shared";
import { generateToolboxAnnotations } from "@shared/ui/analytics/charts/utils";

import { useConsumption } from "./Consumption.store";
import { consumptionLogger, isTemperature } from "./utils";

const PREFIX = "DurationDiagram";

const classes = {
  diagramSortDropDown: `${PREFIX}-diagramSortDropDown`,
};

const StyledGraphContainer = styled(GraphContainer)(({ theme }) => ({
  [`& .${classes.diagramSortDropDown}`]: {
    border: `${theme.palette.primary.main} solid 1px`,
  },
}));

const logger = consumptionLogger.getSubLogger({ name: "<DurationDiagram/>" });
const HEIGHT = 460;

export const DurationDiagram = withErrorBoundary(
  observer(() => {
    const theme = useTheme();
    const { t } = useTranslation(["extendView"]);
    const { averaged, hasPartialData, flowLimiter, chartWidth, seriesInRanges } = useConsumption();
    const [sortColumn, setSortColumn] = useState("heat");
    const [durationDiagramToolbox, setDurationDiagramToolbox] = useState([]);
    const [visibleSeries, setVisibleSeries] = useState({
      heat: true,
      vol: false,
      st: false,
      rt: false,
      dt: false,
    });

    const chartId = "duration";

    // Sort series based on the selected column
    const sortedSeries = useMemo(() => {
      let sortedInRanges = [];

      sortedInRanges = [...seriesInRanges].sort((a, b) => {
        const x = a[sortColumn];
        const y = b[sortColumn];
        if (x === y) return 0;

        if (!isValue(x)) return 1;

        if (!isValue(y)) return -1;

        return x < y ? 1 : -1;
      });

      return sortedInRanges;
    }, [seriesInRanges, sortColumn]);

    // Prepare chart series and axis
    const { series, yAxis } = useMemo(() => {
      const seriesArr = [];
      const yAxisArr = [];

      if (!hasPartialData || !seriesInRanges) return { series: seriesArr, yAxis: yAxisArr };

      let yAxisSeries = null;

      // How many of RT, ST or DT series are visible
      const visibleTemperatureSeries = Object.keys(visibleSeries).reduce((prev, current) => {
        if (!isTemperature(current)) return prev;
        if (visibleSeries[current]) return prev + 1;
        return prev;
      }, 0);

      if (visibleTemperatureSeries) {
        yAxisArr.push({
          visible: true,
          lineWidth: 1,
          opposite: false,
          title: {
            useHTML: true,
            text: `<span class="${chartId}-title-temp">${t("text_temperature")}</span>`,
          },
          yUnit: "°C",
          labels: {
            align: "right",
            x: -15,
          },
        });
      }

      columns
        .filter((column) => column.showInLegend)
        .forEach(({ key, ...col }) => {
          const type = sortColumn === key ? "line" : "scatter";
          const color = theme.palette[col.color[0]][col.color[1]];
          const chartColor = theme.palette[col.lineColor[0]][col.lineColor[1]];
          const visible = visibleSeries[key];
          const name = col.suffix === "°C" ? `${t(col.name)}` : t(col.name);
          let yAxisIndex = yAxisArr.length;

          if (isTemperature(key)) yAxisIndex = 0;

          let data = [];
          if (visible) data = sortedSeries.map((s) => s[key]);

          seriesArr.push({
            id: key,
            type,
            name,
            data,
            timeStamp: sortedSeries.map((s) => s.ts),
            color,
            chartColor,
            visible,
            tooltip: {
              valueSuffix: col.suffix,
            },
            yAxis: yAxisIndex,
            allowPointSelect: true,
            marker: {
              fillColor: color,
              radius: 3,
              symbol: "circle",
            },
            events: {
              legendItemClick: createSeriesToggleHandler(setVisibleSeries),
            },
            showInLegend: col.showInLegend,
          });

          yAxisSeries = {
            visible,
            labels: {
              align: "right",
              x: -15,
              color,
            },
            chartColor: theme.palette[col.lineColor[0]][col.lineColor[1]],
            lineWidth: 1,
            resize: {
              enabled: true,
            },
            title: {
              useHTML: true,
              text: `<span class="${chartId}-title-${key}">${name}</span>`,
            },
            yUnit: col.suffix,
          };

          // Draw the plot line for "Flow Limiter" if its exists
          yAxisSeries.plotLines = undefined;
          if (key === "vol" && flowLimiter) {
            yAxisSeries.plotLines = [
              generateFlowLimiterPlot(flowLimiter, t("text_chart_label_flow_limiter")),
            ];
          }

          if (!isTemperature(key)) yAxisArr.push(yAxisSeries);
        });

      return { series: seriesArr, yAxis: yAxisArr };
    }, [
      flowLimiter,
      hasPartialData,
      seriesInRanges,
      sortColumn,
      sortedSeries,
      t,
      theme.palette,
      visibleSeries,
    ]);

    const handleDiagramChartSort = useCallback((value) => {
      setSortColumn(value);
    }, []);

    logger.trace("RENDER");

    return (
      <StyledGraphContainer
        title={t("text_duration_diagram")}
        subTitles={
          <>
            {`${t("text_sort_by")}:`}
            <ButtonDropdown
              options={DIAGRAM_SORT_OPTIONS}
              onChange={handleDiagramChartSort}
              selectedValue={sortColumn}
              className={classes.diagramSortDropDown}
              data-testid="open-sort-by-btn"
              translationNs="extendView"
            />
            <Box
              display="flex"
              alignItems="center"
              paddingLeft={2}
              borderLeft={2}
              borderColor="grey.100"
              height="100%"
            >
              <ChartToolbox
                className={classes.diagramSortDropDown}
                values={durationDiagramToolbox}
                setValues={setDurationDiagramToolbox}
                disabled={!hasPartialData}
                data-testid={`${chartId}-toolbox`}
              />
            </Box>
          </>
        }
        data-testid={`chart-${chartId}-container`}
      >
        <HighchartsBase
          chart={{
            id: chartId,
            height: HEIGHT,
            marginTop: 60,
            width: chartWidth,
            zoomType: "xy",
          }}
          legend={{
            enabled: true,
            layout: "horizontal",
            align: "left",
            verticalAlign: "top",
            itemMarginTop: -10, // some space between chart unit and legend
            labelFormatter() {
              const { color, name, userOptions } = this;
              return `<div data-testid="${chartId}__legend__${userOptions.id}" style="border-bottom: 3px solid ${color}">${name}</div>`;
            },
            itemStyle: {
              lineHeight: "60%",
              paddingTop: "10px",
              ...theme.typography.body2,
            },
          }}
          tooltip={{
            split: true,
            formatter() {
              const { points, point } = this;
              const { index, series: pointSeries } = point || points[0].point;

              let pointTimestamp = DateTime.fromMillis(pointSeries.options.timeStamp[index]);

              if (averaged) pointTimestamp = pointTimestamp.set({ hour: 0, minute: 0 });

              const formattedDate = pointTimestamp.toFormat(DATETIME_FORMAT_DEFAULT);

              if (points) {
                const valX = formatNumberForLocale(points[0].x);
                const k = [`<b>${valX}</b>`];
                points.forEach((p) => {
                  k.push(
                    `<b> ${p.series.name} ${formatNumberForLocale(p.y)} ${
                      p.series.tooltipOptions.valueSuffix
                    }<br />${formattedDate}</b>`
                  );
                });
                return k;
              }

              const valY = formatNumberForLocale(point.y);
              const unit = point.series.tooltipOptions.valueSuffix;
              return `<b>${point.series.name} ${valY} ${unit} <br /> ${formattedDate}</b>`;
            },
          }}
          annotations={generateToolboxAnnotations({
            series,
            options: durationDiagramToolbox,
          })}
          series={series}
          yAxis={yAxis}
          xAxis={{
            gridLineWidth: durationDiagramToolbox.includes(TOOLBOX_OPTION_GRAPH_GRID) ? 1 : 0,
          }}
          navigation={{
            buttonOptions: {
              enabled: series.length > 0,
            },
          }}
        />
      </StyledGraphContainer>
    );
  })
);

DurationDiagram.displayName = "Consumption.DurationDiagram";
